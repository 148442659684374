import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import ModalHeader from "./ModalHeader";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../common/form/Button";
import GenericDescriptionInput from "../document/forms/formComponentes/GenericDescriptionInput";
import generatorService from "../../core/services/generator.service";
import { useState } from "react";
import { websiteService } from "../../core/services/website.service";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { attachmentsState } from "../../state/attachmentsState";

const DialogStyled = styled(Dialog)`
  padding: 0;
  .p-dialog-header {
    padding: 0;
  }

  .p-dialog-content {
    padding: 0;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

const Description = styled.p`
  margin: 0 0 1rem 0;
  color: #666;
  font-size: 0.875rem;
`;

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ImageWrapper = styled.div`
  img {
    height: 300px;
  }
`;

const FormWrapper = styled.div`
  width: 30%;
`;

const ImagePreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  img {
    width: 100%;
    border-radius: 12px;
  }
`;

const GenerateMediaModal: React.FC<{ onHide: () => void }> = ({ onHide }) => {
  const methods = useForm();
  const { websiteId } = useParams();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const [attachments, setAttachments] = useRecoilState(
    attachmentsState(websiteId as string)
  );

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const load = toast.loading("יוצר תמונה. התהליך עשוי לקחת עד דקה...");
    try {
      const image = await generatorService.generateImage(data.prompt);
      setImageUrl(image);
    } catch (err) {
    } finally {
      setIsLoading(false);
      toast.dismiss(load);
    }
  };

  const saveImage = async () => {
    const load = toast.loading("שומר תמונה בגלריה");
    if (imageUrl && websiteId) {
      try {
        const newAttachment = await websiteService.saveAttachmentToGallery(
          websiteId,
          imageUrl
        );
        setAttachments([...attachments, newAttachment]);
        toast.success("התמונה נשמרה בהצלחה");
        onHide();
      } catch (err) {
        toast.error("שגיאה בשמירת התמונה");
      } finally {
        toast.dismiss(load);
      }
    }
  };

  return (
    <DialogStyled
      visible
      position={"right"}
      style={{
        height: "100vh",
        margin: "0",
        width: "75vw",
        boxShadow: "none",
        borderLeft: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
        padding: "0",
      }}
      onHide={onHide}
      header={<ModalHeader OnClose={onHide} right downAbit />}
      modal={true}
      draggable={false}
      closable={false}
    >
      <FormProvider {...methods}>
        <form>
          <Wrapper>
            <FormWrapper>
              <Title>צור תמונה באמצעות AI</Title>
              <Description>
                הזינו תיאור טקסטואלי מפורט וקבלו תמונה שנוצרה במיוחד עבורכם!
              </Description>

              <div style={{ width: 300 }}>
                <GenericDescriptionInput<{ prompt: string }>
                  name="prompt"
                  label="תיאור התמונה"
                  placeholder={`תתארו כאן מה בדיוק אתם רוצים שיהיה בתמונה`}
                />
              </div>
              <br />
              <Button
                loading={isLoading}
                primary
                onClick={methods.handleSubmit(onSubmit)}
              >
                צור תמונה
              </Button>
            </FormWrapper>

            <ImagePreview>
              {imageUrl && <img src={imageUrl} alt="generated" />}
              <br />
              {imageUrl && (
                <Button primary onClick={saveImage}>
                  לחץ לשמירה בגלריה
                </Button>
              )}
            </ImagePreview>
          </Wrapper>
        </form>
      </FormProvider>
    </DialogStyled>
  );
};

export default GenerateMediaModal;
