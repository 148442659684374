import styled from "styled-components";
import Avatar, { genConfig } from "react-nice-avatar";
import { FieldValues, Path } from "react-hook-form";
import RadioGroup from "../../common/form/RadioGroup";
import Badge from "../../common/Badge";
import { ReactComponent as ToneYellow } from "../../../assets/Icons/tone/ToneYellow.svg";
import { ReactComponent as ToneRed } from "../../../assets/Icons/tone/ToneRed.svg";
import { ReactComponent as ToneBlue } from "../../../assets/Icons/tone/ToneBlue.svg";
import { ReactComponent as ToneDark } from "../../../assets/Icons/tone/ToneDark.svg";
import { Tooltip } from "primereact/tooltip";
import { ReactComponent as TonePurpel } from "../../../assets/Icons/tone/TonePurpel.svg";
import { DocumentToneType } from "neword-core";
import { ReactComponent as InfoIcon } from "../../../assets/Icons/Info.svg";
import { tonePromptFactory } from "../factory/tonePromptFactory";
import { useRecoilValue } from "recoil";
import { toneState } from "../../../state/toneState";
import { useMatch, useParams } from "react-router";
import { useMemo, useState } from "react";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/PlusBorder.svg";
import CreateToneModal from "../../common/form/createToneModal";

interface Props<T> {
  threeColumn?: boolean;
  fieldName: Path<T>;
}

const TooltipTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    width: 20px;
    height: 20px;
  }
  span {
    font-weight: 600;
    color: var(--title-color);
  }
`;

const InsideRadio = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;

  .infoicon {
    width: 14px;
    height: 14px;
    right: -27px;
    top: 0;
    path {
      fill: #bdc7ca !important;
    }
  }
`;

const RadioWrapper = styled.div`
  .singleradio {
    background: white;
  }
  span {
    color: var(--title-color);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const IconWrapper = styled.div`
  position: absolute;
  bottom: 12px;
  left: 17px;
  opacity: 0;
  transition-duration: 0.1s;
`;

const AudienceCard = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;

  &.add-audience {
    border: 1px dashed #ddd;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    border-color: var(--title-color);

    ${IconWrapper} {
      opacity: 1;
    }
  }
`;

const TooltipStyled = styled(Tooltip)``;

function DocumentTone<FormDataType extends FieldValues>(
  props: Props<FormDataType>
) {
  const { websiteId } = useParams();
  const [createToneModal, showCreateToneModal] = useState(false);

  const tones = useRecoilValue(toneState(websiteId as string));

  const websiteTones = useMemo(
    () =>
      tones.map((tone) => {
        return {
          value: tone.summerizedToneText,
          render: (
            <>
              <TooltipStyled target=".infoicon.convincing">
                <div className="p-4">
                  <TooltipTitle>
                    <ToneDark style={{ width: "1.5rem", height: "1.5rem" }} />
                    <span>{tone.toneText}</span>
                  </TooltipTitle>
                </div>
              </TooltipStyled>
              <InsideRadio className="insideradio">
                <ToneDark style={{ width: "1.5rem", height: "1.5rem" }} />
                <span>{tone.name}</span>
                <InfoIcon
                  className="infoicon convincing"
                  data-pr-position="top"
                />
              </InsideRadio>
            </>
          ),
        };
      }),
    [tones]
  );
  return (
    <>
    <Wrapper>
      <RadioWrapper>
        <RadioGroup<FormDataType, string>
          ColumnNumber={"2"}
          fieldName={props.fieldName}
          options={[
            {
              value: tonePromptFactory["MARKETING"],
              render: (
                <>
                  <TooltipStyled target=".infoicon.marketing">
                    <div className="p-4">
                      <TooltipTitle>
                        <ToneYellow style={{ width: "1.5rem", height: "1.5rem" }} />
                        <span>
                          שיווקי | כתיבה מכירתית ממוקדת שמטרתה להניע לפעולה.
                        </span>
                      </TooltipTitle>
                      <small>
                        דוגמה: "רוצים להגדיל את החשיפה שלכם? הצטרפו עכשיו!"
                      </small>
                    </div>
                  </TooltipStyled>
                  <InsideRadio className="insideradio">
                    <ToneYellow style={{ width: "1.5rem", height: "1.5rem" }} />
                    <span>שיווקי</span>
                    <InfoIcon className="infoicon marketing" data-pr-position="top" />
                  </InsideRadio>
                </>
              ),
            },
            {
              value: tonePromptFactory["PERSUASIVE"],
              render: (
                <>
                  <TooltipStyled target=".infoicon.persuasive">
                    <div className="p-4">
                      <TooltipTitle>
                        <ToneRed style={{ width: "1.5rem", height: "1.5rem" }} />
                        <span>
                          משכנע | מבוסס טכניקות שכנוע, סיפורים ונתונים.
                        </span>
                      </TooltipTitle>
                      <small>
                        דוגמה: "90% מהמשתמשים ממליצים – למה שלא תנסו גם?"
                      </small>
                    </div>
                  </TooltipStyled>
                  <InsideRadio className="insideradio">
                    <ToneRed style={{ width: "1.5rem", height: "1.5rem" }} />
                    <span>משכנע</span>
                    <InfoIcon className="infoicon persuasive" data-pr-position="top" />
                  </InsideRadio>
                </>
              ),
            },
            {
              value: tonePromptFactory["INFORMATIVE"],
              render: (
                <>
                  <TooltipStyled target=".infoicon.informative">
                    <div className="p-4">
                      <TooltipTitle>
                        <ToneBlue style={{ width: "1.5rem", height: "1.5rem" }} />
                        <span>
                          אינפורמטיבי | מסביר ומבוסס עובדות בצורה ברורה.
                        </span>
                      </TooltipTitle>
                      <small>
                        דוגמה: "מחקרים מראים כי שימוש נכון בטכניקות שיווק מעלה מכירות ב-30%."
                      </small>
                    </div>
                  </TooltipStyled>
                  <InsideRadio className="insideradio">
                    <ToneBlue style={{ width: "1.5rem", height: "1.5rem" }} />
                    <span>אינפורמטיבי</span>
                    <InfoIcon className="infoicon informative" data-pr-position="top" />
                  </InsideRadio>
                </>
              ),
            },
            {
              value: tonePromptFactory["FRIENDLY"],
              render: (
                <>
                  <TooltipStyled target=".infoicon.friendly">
                    <div className="p-4">
                      <TooltipTitle>
                        <ToneBlue style={{ width: "1.5rem", height: "1.5rem" }} />
                        <span>
                          ידידותי | קליל ונעים, כמו שיחה עם חברים.
                        </span>
                      </TooltipTitle>
                      <small>
                        דוגמה: "נו מה אתם אומרים? אתם הולכים לעוף על זה!"
                      </small>
                    </div>
                  </TooltipStyled>
                  <InsideRadio className="insideradio">
                    <ToneBlue style={{ width: "1.5rem", height: "1.5rem" }} />
                    <span>ידידותי</span>
                    <InfoIcon className="infoicon friendly" data-pr-position="top" />
                  </InsideRadio>
                </>
              ),
            },
            {
              value: tonePromptFactory["TRENDY_PROVOCATIVE"],
              render: (
                <>
                  <TooltipStyled target=".infoicon.trendy">
                    <div className="p-4">
                      <TooltipTitle>
                        <ToneBlue style={{ width: "1.5rem", height: "1.5rem" }} />
                        <span>
                          טרנדי | מבוסס רפרנסים תרבותיים וסלנג ישראלי.
                        </span>
                      </TooltipTitle>
                      <small>
                        דוגמה: "2024 כאן, ואתם עדיין משתמשים בטכניקות של 2010? יאללה להשתדרג!"
                      </small>
                    </div>
                  </TooltipStyled>
                  <InsideRadio className="insideradio">
                    <ToneBlue style={{ width: "1.5rem", height: "1.5rem" }} />
                    <span>טרנדי</span>
                    <InfoIcon className="infoicon trendy" data-pr-position="top" />
                  </InsideRadio>
                </>
              ),
            },
            {
              value: tonePromptFactory["HUMOROUS"],
              render: (
                <>
                  <TooltipStyled target=".infoicon.humorous">
                    <div className="p-4">
                      <TooltipTitle>
                        <ToneBlue style={{ width: "1.5rem", height: "1.5rem" }} />
                        <span>
                          הומוריסטי | משעשע עם משחקי מילים והומור קליל.
                        </span>
                      </TooltipTitle>
                      <small>
                        דוגמה: "אמרו לנו לכתוב פוסט שיווקי. אז הנה: קנו אותנו עכשיו ותקבלו חיוך חינם!"
                      </small>
                    </div>
                  </TooltipStyled>
                  <InsideRadio className="insideradio">
                    <ToneBlue style={{ width: "1.5rem", height: "1.5rem" }} />
                    <span>הומוריסטי</span>
                    <InfoIcon className="infoicon humorous" data-pr-position="top" />
                  </InsideRadio>
                </>
              ),
            },
            {
              value: tonePromptFactory["FORMAL"],
              render: (
                <>
                  <TooltipStyled target=".infoicon.formal">
                    <div className="p-4">
                      <TooltipTitle>
                        <ToneDark style={{ width: "1.5rem", height: "1.5rem" }} />
                        <span>
                          פורמלי | רשמי, מקצועי וללא סלנג.
                        </span>
                      </TooltipTitle>
                      <small>
                        דוגמה: "בהתאם למחקרים האחרונים, שוק ה-Ecommerce צפוי לצמוח ב-15% בשנת 2025."
                      </small>
                    </div>
                  </TooltipStyled>
                  <InsideRadio className="insideradio">
                    <ToneDark style={{ width: "1.5rem", height: "1.5rem" }} />
                    <span>פורמלי</span>
                    <InfoIcon className="infoicon formal" data-pr-position="top" />
                  </InsideRadio>
                </>
              ),
            },
            ...websiteTones,
          ]}
        />
        <div className="col-12">
          <AudienceCard className="p-2 add-audience" onClick={() => showCreateToneModal(true)}>
            <PlusIcon className="icon" />
            הוסף סגנון כתיבה בשפה שלך!
          </AudienceCard>
        </div>
      </RadioWrapper>
    </Wrapper>
  
    {createToneModal && <CreateToneModal onHide={() => showCreateToneModal(false)} />}
  </>
  
  );
}

export default DocumentTone;
