import { AttachmentMedia } from "neword-core";

export default (value: AttachmentMedia[]) => {
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  let errorText = "";

  // Validation for Facebook Reels metadata
  videos.map((videoItem) => {
    const video = videoItem.metadata;

    if (video.type === "VIDEO") {
      const [width, height] = video.aspectRatio.split(":").map(Number);
      const aspectRatio = width / height;
      if (aspectRatio < 0.5625 || aspectRatio > 1.91) {
        errorText = "הווידאו חייב להיות ביחס רוחב-גובה בין 1.91:1 ל-9:16";
      }

      // Check resolution
      const [resWidth, resHeight] = video.resolution.split("x").map(Number);
      if (resWidth < 540 || resHeight < 960) {
        errorText = "הווידאו חייב להיות ברזולוציה מינימלית של 540x960 פיקסלים";
      }

      // Check duration
      if (video.duration < 3 || video.duration > 90) {
        errorText = "אורך הווידאו צריך להיות בין 3 ל-90 שניות";
      }

      // Check frame rate
      if (video.frameRate < 24 || video.frameRate > 60) {
        errorText =
          "קצב הפריימים של הווידאו צריך להיות בין 24 ל-60 פריימים לשנייה";
      }
    }
  });

  return errorText;
};
