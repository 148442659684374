import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/Icons/TiktokLogo.svg";
import Card from "../../common/Card";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import { AttachmentMedia, PostEntity, PostEntityType } from "neword-core";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import FacebookPreview from "../../../assets/images/facebookPreview.png"; // Add Facebook preview image
import { Galleria } from "primereact/galleria";
import { ReactComponent as Edit } from "../../../assets/Icons/EditImage.svg";
import { ReactComponent as Remove } from "../../../assets/Icons/Times.svg";
import MediaEditor from "../MediaEditor";

const Wrapper = styled(Card)`
  position: relative;
  padding: 15px;
  width: 100%;
  min-height: 400px;
  max-width: 300px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
`;

const AccountName = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-family: "Helvetica", "Arial", sans-serif;
`;

const PostTime = styled.div`
  font-size: 12px;
  color: #65676b;
  margin-inline: 10px;
`;

const ContentWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const SyledGalleria = styled(Galleria)`
  position: relative;
  margin-bottom: 10px;

  .p-galleria-item {
    background-color: black;
    border-radius: 8px;
  }

  .p-galleria-item-next {
    right: auto !important;
    left: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-item-prev {
    left: auto !important;
    right: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-indicator button {
    width: 0.5rem !important;
    height: 0.5rem !important;
  }

  .p-galleria-indicators {
    padding: 0 !important;
  }

  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: var(--primary-purple);
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 9 / 16;
    border-radius: 8px;
    max-width: 350px;
  }
`;

const PostImage = styled.img`
  aspect-ratio: 9 / 16;
  border-radius: 8px;
  object-fit: cover;
  max-width: 350px;
`;
const ToolWrapper = styled.div`
  gap: 10px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: white;
  }
  display: flex;
  height: 40px;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 6px;
  transition: background-color 0.2s;
  z-index: 99999;
  svg {
    fill: white;
    width: 20px;
    height: 20px;
  }

  path {
    fill: white;
  }

  &:hover {
    background-color: #2c2c2c;
  }
`;

const Tools = styled.div`
  gap: 10px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 99;
`;

function TiktokPreview<FormDataType extends FieldValues>({
  name,
  reel = false, // Default is false
}: {
  name: Path<FormDataType>;
  reel?: boolean;
}) {
  const methods = useFormContext();
  const { websiteId } = useParams();
  const website = useRecoilValue(websiteState(websiteId as string));
  const values = useWatch({ control: methods.control, name: name }); // Ensure posts has a default value

  const [showMediaEditorModal, setMediaEditorModal] = useState(false);
  const imageUrl = useMemo(() => {
    return values?.imageUrls || FacebookPreview; // Default to empty string if no image is available
  }, [values]);

  return (
    <Wrapper>
      <TopSection>
        <AccountName>{website?.name}</AccountName>
        <PostTime>Just now</PostTime>
      </TopSection>
      <ContentWrapper>{values && values.message}</ContentWrapper>
      <div className="pos-rel"></div>

      <SyledGalleria
        value={
          imageUrl.length > 0
            ? imageUrl
            : [{ url: FacebookPreview, metadata: { type: "IMAGE" } }]
        }
        showThumbnails={false}
        showIndicators
        item={(item: AttachmentMedia) => {
          if (!item) return;
          return (
            <>
              <Tools>
                <ToolWrapper
                  onClick={() =>
                    methods.setValue(
                      name + ".imageUrls",
                      values.imageUrls.filter(
                        (m: AttachmentMedia) => m !== item
                      )
                    )
                  }
                >
                  <Remove />
                  <span>הסר</span>
                </ToolWrapper>
                <ToolWrapper onClick={() => setMediaEditorModal(true)}>
                  <Edit />
                  <span>עריכת תמונה/וידאו</span>
                </ToolWrapper>
              </Tools>

              {item.metadata.type === "VIDEO" ? (
                <video
                  src={item.url}
                  controls
                  style={{ aspectRatio: reel ? "9/16" : "1/1" }}
                />
              ) : (
                <PostImage src={item.url} />
              )}
              {showMediaEditorModal && (
                <MediaEditor
                  url={item.url}
                  onProcess={(attachment: AttachmentMedia) =>
                    methods.setValue(
                      name + ".imageUrls",
                      values.imageUrls.map((m: AttachmentMedia) =>
                        m === item ? attachment : m
                      )
                    )
                  }
                  imageSettings={{
                    imageCropAspectRatio: 9 / 16, // Maintain TikTok's vertical aspect ratio
                  }}
                  imageWriterSettings={{
                    targetSize: {
                      width: 1080, // TikTok preferred width
                      height: 1920, // TikTok preferred height
                    },
                  }}
                  videoSettings={{
                    framesPerSecond: 30, // TikTok-compatible frame rate
                    videoBitrate: 2500000, // 2.5 Mbps for HD videos
                  }}
                  onClose={() => setMediaEditorModal(false)}
                />
              )}
            </>
          );
        }}
      />
      <IconWrapper>
        <Logo width="20" height="20" />
      </IconWrapper>
    </Wrapper>
  );
}

export default TiktokPreview;
