import { z } from "zod";

export const ZDocumentToneType = z.enum([
  "MARKETING",
  "PERSUASIVE",
  "INFORMATIVE",
  "FRIENDLY",
  "INSPIRED",
  "HUMOROUS",
  "TRENDY_PROVOCATIVE",
  "MYSTERIOUS",
  "FORMAL"
]);
export type DocumentToneType = z.infer<typeof ZDocumentToneType>;
