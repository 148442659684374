import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeflex/primeflex.css";
import "./App.css";
import "./prime.override.css";
import "primeicons/primeicons.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import routes from "./routes";
import PageWrapper from "./components/core/PageWrapper";
import { RecoilRoot } from "recoil";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalModalContainer from "./components/modals/globalModal/globalModalContainer";
import AppWrapper from "./wrappers/AppWrapper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TourProvider } from "./components/appGuide/TourProvider";
import { MountPoint } from "./components/core/MountPoint";
import i18 from "./i18s"; // Translation
import { useEffect } from "react";

const Wrapper = styled.div<{ dir: "rtl" | "ltr" }>`
  /* min-height: 100vh; */
  padding: 0em;
`;

function App() {
  useEffect(() => {
    const html = document.documentElement;
    html.className = i18.dir();
  }, [i18.language]); // Re-run when language changes

  //i18.changeLanguage("he");

  return (
    <Wrapper dir={i18.dir()}>
      <RecoilRoot>
        <TourProvider>
          <AppWrapper>
            <MountPoint />
            <DndProvider backend={HTML5Backend}>
              <BrowserRouter>
                <Routes>
                  {routes.map((route) => {
                    return (
                      <Route
                        element={<PageWrapper Route={route}></PageWrapper>}
                        key={route.path}
                        path={route.path}
                      />
                    );
                  })}
                </Routes>
              </BrowserRouter>
              <GlobalModalContainer />
              <ToastContainer rtl={true} />
            </DndProvider>
          </AppWrapper>
        </TourProvider>
      </RecoilRoot>
    </Wrapper>
  );
}

export default App;
