import { InputText } from "primereact/inputtext";
import { ReactComponent as GoogleIcon } from "../assets/Icons/ColoredGoogle.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import userService from "../core/services/user.service";
import { useSetRecoilState } from "recoil";
import { userState } from "../state/userState";
import styled from "styled-components";
import { SubmitHandler, useForm } from "react-hook-form";
import { LoginRequestData } from "../core/services/requests/login/loginRequestData";
import { Message } from "primereact/message";
import RegexValidations from "../core/validation/regexValidations";
import FormStyle from "../components/common/form/FormStyle";
import Button from "../components/common/form/Button";
import Link from "../components/common/Link";
import AppConfig from "../config/appConfig";
import AuthHeader from "../components/auth/AuthHeader";
import ErrorMessage from "../components/common/ErrorMessage";
import Testimonials from "../assets/images/comapnies.png";
import bgImage from "../assets/images/loginimage.png";

const LoginPageWrapper = styled.div`
height: 98.2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 1000px) {
    .form-mobile {
      width: 80%;
    }

    label {
      font-size: 16px;
    }

    input {
      font-size: 22px;
      height: 75px;
    }

    .google-button-mobile {
      height: 75px;
      font-size: 18px;
    }

    .or-text-mobile {
      font-size: 20px;
      margin: 50px 0;
    }

    #gw-button {
      text-align: center;
      justify-content: center;
      height: 75px;
      font-size: 18px;
      width: 100%;
      svg {
        margin-left: 20px;
      }
    }
    .small-text-mobile {
      margin-top: 30px;
      font-size: 18px;
      a {
        font-size: 18px;
      }
    }

    .mobile-header {
      position: relative;

      svg {
        width: 100px;
        height: 60px;
      }
      h1 {
        width: 100%;
        font-size: 2rem;
      }
    }
  }
`;

const Title = styled.h1`
  color: var(--main-title-color, #0a2540);
  text-align: center;

  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 3rem */
  letter-spacing: -0.12rem;
  text-transform: capitalize;
  margin: 60px 0;
  width: 37.625rem;
  & span {
    color: var(--main-purple, #a960ee);
  }
`;

const ErrorMessageStyled = styled(Message)`
  display: block;
  width: fit-content;
  margin-bottom: 20px;
`;

const CenteredForm = styled(FormStyle)`
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 40%;
  justify-content: center;
  align-items: center;
`;

const ColoredLink = styled(Link)`
  color: var(--primary-purple);
  font-family: "Assistant";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.75rem */
  letter-spacing: -0.0225rem;
  text-transform: capitalize;
  text-decoration: underline;
`;

const RegularText = styled.div`
  margin-top: 60px;
  color: var(--primary-purple);
  font-family: "Assistant";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.75rem */
  letter-spacing: -0.0225rem;
  text-transform: capitalize;
`;

const Passlink = styled(Link)`
  display: block;
  padding-top: 10px;
  text-decoration: underline;
  color: var(--primary-title);
  font-family: "Assistant";
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.75rem */
  letter-spacing: -0.0225rem;
  text-transform: capitalize;
`;

const GoogleButton = styled(Link)`
  border-radius: 6px;
  border: 1px solid #1d58e3;
  display: flex;
  height: 3.125rem;
  width: 100%;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  margin-bottom: 30px;
  color: #1d58e3;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 0.75rem */
  letter-spacing: -0.0225rem;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    border-color: var(--title-color);
  }
  & svg {
    width: 16px;
  }
`;

const OrText = styled.div`
  color: var(--main-text-color, #425466);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 0.75rem */
  letter-spacing: -0.0225rem;
  text-transform: capitalize;
  margin: 20px 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 310%;
    height: 1px;
    background: var(--main-text-color, #425466);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: -1;
  }

  &:after {
    content: "";
    position: absolute;
    width: 130%;
    height: 20px;
    background: white;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: -1;
  }
`;

const Bg = styled.div`
  h4 {
    color: white;
  }
  background: var(--primary-purple);
  height: 100%;
  padding: 40px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .purple {
    /* color: var(--primary-purple); */
    color: #ffcb57;
  }

  span {
    color: white;
    font-size: 2.2rem;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 1.5rem */
    letter-spacing: -0.045rem;
    text-transform: capitalize;
  }
`;

const TestimonialBlock = styled.div`
  background: white;
  border-radius: 10px;
  padding: 40px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h4 {
    font-size: 2.094rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 2.094rem */
    letter-spacing: -0.10469rem;
    color: var(--primary-purple);
  }

  svg path {
    fill: var(--primary-purple);
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 147%; /* 1.15431rem */
    letter-spacing: -0.02356rem;
  }
`;

const Wrapper = styled.div`
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const ImgWrapper = styled.div`
  margin-left: -40px;
  margin-right: -40px;

  img {
    width: 100%;
  }
`;

const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginRequestData>();
  const setUserState = useSetRecoilState(userState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const location = useLocation();
  const onSubmit: SubmitHandler<LoginRequestData> = async (data) => {
    try {
      setIsLoading(true);
      const res = await userService.login(data);
      setUserState(res.user);
      const redirectPath = queryParams.get("redirect") || "/";
      navigate(redirectPath);
    } catch (err) {
      setError("root", {});
    } finally {
      setIsLoading(false);
    }
  };

  const onClickGoogleButton = () => {
    const redirectPath = queryParams.get("redirect") || "/";
    document.location.href = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${
      AppConfig.serverUrl
    }/auth%2Fgoogle%2Fcallback&client_id=89013921262-762mm9l2lfq3dfv4rf185srjgq8ulihg.apps.googleusercontent.com&access_type=offline&response_type=code&prompt=select_account&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&state=${encodeURIComponent(
      redirectPath
    )}`;
  };

  useEffect(() => {
    (window as any).google.accounts.id.initialize({
      client_id:
        "89013921262-762mm9l2lfq3dfv4rf185srjgq8ulihg.apps.googleusercontent.com",
      auto_select: true,
      itp_support: true,
      login_uri: `${AppConfig.serverUrl}/auth/google/callback`,
      callback: (response: any) => {
        const credentials = response.credential;
        document.location.href = `${AppConfig.serverUrl}/auth/google/onetap?credentials=${credentials}`;
      },
    });

    (window as any).google.accounts.id.prompt(); // Trigger the One Tap UI
  }, []);

  return (
    <>
      <Wrapper className="grid m-0">
        <div className="lg:col-8 sm:col-12">
          <LoginPageWrapper>
            <div className="mobile-header">
              <AuthHeader />
            </div>

            <CenteredForm
              className="form-mobile"
              onKeyDown={(e) => e.keyCode === 13 && handleSubmit(onSubmit)(e)}
            >
              <GoogleButton
                onClick={onClickGoogleButton}
                className="google-button-mobile"
              >
                <GoogleIcon />
                כניסה מהירה עם גוגל
              </GoogleButton>
              <div className="w-full flex align-items-center justify-content-center">
                <OrText className="or-text-mobile">או עם פרטי המייל</OrText>
              </div>
              <div className="w-full mb-5">
                <label>אימייל</label>
                <InputText
                  {...register("username", {
                    required: true,
                    maxLength: 40,
                    pattern: {
                      value: RegexValidations.email,
                      message: "כתובת אימייל לא חוקית",
                    },
                  })}
                  type="text"
                  placeholder="כתובת דואר אלקטרוני"
                  className={errors.username ? "p-invalid" : ""}
                />
                <ErrorMessage>{errors.username?.message}</ErrorMessage>
              </div>
              <div className="w-full mb-5">
                <label>סיסמה</label>

                <InputText
                  {...register("password", { required: true, maxLength: 20 })}
                  type="password"
                  placeholder="סיסמה"
                  className={errors.password ? "p-invalid" : ""}
                />
                <Passlink className=" small-text-mobile" path="/reset-password">
                  הסיסמה נשכחה?
                </Passlink>

                {errors.root && (
                  <ErrorMessageStyled
                    className="mt-2"
                    severity="error"
                    text="שם המשתמש והסיסמה אינם נכונים"
                  />
                )}
              </div>

              <Button
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
                primary
                arrowPlacement="right"
              >
                התחבר לחשבון{" "}
              </Button>

              <RegularText className="mb-4 small-text-mobile">
                עדיין לא הצטרפת?{" "}
                <ColoredLink path="/signup">ל-30 ימי נסיון חינם</ColoredLink>
              </RegularText>
              <div className="flex gap-10">
                <a href="https://he.neword.ai/pp-he/" className="small-text-mobile">
                  מדיניות פרטיות (PP)
                </a>
                <a href="https://he.neword.ai/tos-he/" className="small-text-mobile">
                 תנאי שימוש (ToS)
                </a>
              </div>
            </CenteredForm>
          </LoginPageWrapper>
        </div>

        <div className="lg:col-4 sm:col-12 p-0">
          <Bg>
            <span>
              המערכת היחידה ליצירת תוכן שיווקי
              <span className="purple"> שממיר לקוחות </span>
            </span>
            {/* <img src={Testimonials} width={170} alt="Testimonials" /> */}
            <ImgWrapper>
              <img src={bgImage} alt="Testimonials" />
            </ImgWrapper>
            <div className="flex justify-content-center flex-column">
              <h4 className="mb-3 text-center">בין לקוחותינו</h4>
              <img src={Testimonials} width={270} alt="Testimonials" />
            </div>
          </Bg>
        </div>
      </Wrapper>
    </>
  );
};

export default LoginPage;
