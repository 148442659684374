import React, { useState } from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { postInsightsState } from "../../state/postInsightsState";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PostInsights, PostEntityType } from "neword-core";
import styled from "styled-components";
import { PostCardIcons } from "../../components/post/postIconMappings";
import CalendarMenu from "../../components/calendar/CalendarMenu";
import Card from "../../components/common/Card";
import CardTitle from "../../components/common/CardTitle";
import Button from "../../components/common/form/Button";
import { ReactComponent as AddIcon } from "../../assets/Icons/PlusBorder.svg";
import Link from "../../components/common/Link";
// Styled DataTable with full styling adjustments
const DataTableStyled = styled(DataTable)`
  .p-datatable {
    direction: rtl;
    text-align: right;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }

  .p-row-odd {
    background-color: white !important;
  }
  .p-column-title {
    text-align: center;
  }
  .p-datatable .p-datatable-thead > tr > th,
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: right;
    font-weight: bold;
    background-color: #f8f9fa;
    padding: 10px;
    border-bottom: 1px solid #e9ecef;
  }

  .p-datatable .p-datatable-tbody > tr:hover {
    background-color: #f1f3f5;
  }

  .highlight {
    background-color: #e7f5ff;
    color: #1c7ed6;
    font-weight: bold;
    padding: 5px;
    border-radius: 4px;
    display: inline-block;
    min-width: 50px;
    text-align: center;
  }
`;

const PageWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: 20px;
`;

const NoContentMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  text-align: center;
  font-size: 18px;
  color: #555;
`;

// Function to format date manually
const formatDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const createdAtTemplate = (post: PostInsights) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <small style={{ color: "var(--text-color)" }}>
      {formatDate(new Date(post.created_time))}
    </small>
    <span style={{ fontSize: "0.9em", color: "#555" }}>
      {post.message.length > 50
        ? `${post.message.substring(0, 40)}...`
        : post.message}
    </span>
  </div>
);

const InsightsPage: React.FC = () => {
  const { websiteId } = useParams();
  const postInsights = useRecoilValue(postInsightsState(websiteId as string));
  const [filterPostType, setFilterPostType] = useState<PostEntityType[]>(
    Object.values(PostEntityType) as PostEntityType[]
  );

  const filteredPosts = postInsights.filter(
    (post) => filterPostType.length === 0 || filterPostType.includes(post.type)
  );

  const maxLikes = Math.max(...filteredPosts.map((post) => post.likes), 0);
  const maxShares = Math.max(...filteredPosts.map((post) => post.shares), 0);
  const maxComments = Math.max(
    ...filteredPosts.map((post) => post.comments),
    0
  );

  const formattedPosts = filteredPosts.map((post) => ({
    ...post,
    icon: PostCardIcons[post.type as PostEntityType]?.icon || null,
  }));

  return (
    <PageWrapper>
      <CalendarMenu
        setFilterPostType={setFilterPostType}
        filterPostType={filterPostType}
        toggleModal={() => {}}
        hideToggle
      />
      <Card className="mt-4 pr-0 pl-0 pt-2">
        <CardTitle className="pr-4 pt-3 mb-0" title="כל התכנים" />
        {formattedPosts.length === 0 ? (
          <NoContentMessage>
            <p>אין תכנים להצגה, יש לחבר ערוצי סושיאל כדי להציג נתונים.</p>
            <Link path="/integrations">
              <Button
                icon={<AddIcon />}
                className="p-button-primary mt-3"
                primary
              >
                חבר אינטגרציה
              </Button>
            </Link>{" "}
          </NoContentMessage>
        ) : (
          <DataTableStyled
            value={formattedPosts}
            tableStyle={{ minWidth: "20rem" }}
            stripedRows
          >
            <Column
              body={(post) => (
                <div style={{ textAlign: "center" }}>{post.icon}</div>
              )}
              style={{ textAlign: "center", width: "20px" }}
            />
            <Column
              body={(post) => (
                <img
                  src={post.image}
                  alt="Post"
                  style={{ maxWidth: "80px", borderRadius: "5px" }}
                />
              )}
              style={{ textAlign: "center", width: "70px" }}
            />
            <Column body={createdAtTemplate} style={{ textAlign: "right" }} />
            <Column
              field="likes"
              header="לייקים"
              body={(post) => (
                <span className={post.likes === maxLikes ? "highlight" : ""}>
                  {post.likes}
                </span>
              )}
              style={{ textAlign: "center", width: "70px" }}
            />
            <Column
              field="shares"
              header="שיתופים"
              body={(post) => (
                <span className={post.shares === maxShares ? "highlight" : ""}>
                  {post.shares}
                </span>
              )}
              style={{ textAlign: "center", width: "70px" }}
            />
            <Column
              field="comments"
              header="תגובות"
              body={(post) => (
                <span
                  className={post.comments === maxComments ? "highlight" : ""}
                >
                  {post.comments}
                </span>
              )}
              style={{ textAlign: "center", width: "70px" }}
            />
          </DataTableStyled>
        )}
      </Card>
    </PageWrapper>
  );
};

export default InsightsPage;
