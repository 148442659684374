import { ReactComponent as IgIcon } from "../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as IgIconFlat } from "../../assets/Icons/InstagramIconFlat.svg";
import { ReactComponent as IgPost } from "../../assets/Icons/instagram/Grid.svg";
import { ReactComponent as IgStory } from "../../assets/Icons/instagram/Story.svg";
import { ReactComponent as IgReel } from "../../assets/Icons/instagram/Reel.svg";
import { ReactComponent as Facebook } from "../../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as FacebookFlat } from "../../assets/Icons/FacebookIconFlat.svg";
import { ReactComponent as Linkedin } from "../../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as LinkedinFlat } from "../../assets/Icons/LinkedinIconFlat.svg";
import { ReactComponent as Twitter } from "../../assets/Icons/XLogo.svg";
import { ReactComponent as ReelsIcon } from "../../assets/Icons/ReelsIcon.svg";
import { ReactComponent as TiktokLogo } from "../../assets/Icons/TiktokLogo.svg";
import { PostEntityType } from "neword-core";

export type NestedPostType = {
  type: PostEntityType;
  icon: JSX.Element;
  content: string;
};

export type PlatformPostTypes = {
  icon: JSX.Element;
  iconFlat: JSX.Element; 
  color?: string;
  postTypes: NestedPostType[];
  name?: string;
};

export const NestedPostIcons: Record<string, PlatformPostTypes> = {
  INSTAGRAM: {
    icon: <IgIcon />,
    color: "#e1306c",
    iconFlat:<IgIconFlat />,
    name: "אינסטגרם",
    postTypes: [
      { type: PostEntityType.INSTAGRAM_POST, icon: <IgPost />, content: "פוסט" },
      { type: PostEntityType.INSTAGRAM_REEL, icon: <IgReel />, content: "רילס" },
      { type: PostEntityType.INSTAGRAM_STORY, icon: <IgStory />, content: "סטורי" },
    ],
  },
  FACEBOOK: {
    icon: <Facebook />, 
    iconFlat:<FacebookFlat />,  
    color: "#1877F2",
    name: "פייסבוק",
    postTypes: [
      { type: PostEntityType.FACEBOOK_POST, icon: <IgPost />, content: "פוסט" },
      { type: PostEntityType.FACEBOOK_REEL, icon: <IgReel />, content: "רילס" },
    ],
  },
  LINKEDIN: {
    icon: <Linkedin />,
    color: "#0077B5",
    iconFlat:<LinkedinFlat />, 
    name: "לינקדאין",
    postTypes: [
      { type: PostEntityType.LINKEDIN_POST, icon: <Linkedin />, content: "פוסט" },
    ],
  },
  TWITTER: {
    icon: <Twitter />, 
    iconFlat:<Twitter />,
    color: "#000000",
    name: "טוויטר",
    postTypes: [
      { type: PostEntityType.TWITTER_TWIT, icon: <Twitter />, content: "ציוץ" },
    ],
  },
  TIKTOK: {
    icon: <TiktokLogo />, 
    iconFlat:<TiktokLogo />,
    color: "#000000",
    name: "טיקטוק",
    postTypes: [
      { type: PostEntityType.TIKTOK_POST, icon: <TiktokLogo />, content: "פוסט" },
    ],
  },
};
