import { Dialog } from "primereact/dialog";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import PublishPostModal from "../post/PublishPostModal";
import { PostEntityType } from "neword-core";
import { NestedPostIcons } from "../post/NestedPostIcons";
import { appIntegrationState } from "../../state/appIntegrationState";
import { useFormContext } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../core/services/requests/createWebsite/createWebsiteRequestData";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router";
import { Divider } from "@mui/material";

interface Props {
  onHide: () => void;
  date?: Date;
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 70vh;
  overflow-y: auto;
  padding: 16px;
`;

const PlatformContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
`;

const PostTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  margin-top: 16px;
`;

const PlatformButton = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: none;
  background: none;
  cursor: pointer;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.3)};
  transition: opacity 0.15s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

const PlatformPostButton = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
background: var(--light-bg);
padding: 14px 26px;
border:solid 1px var(--border-color);
border-radius:8px;
cursor: pointer;  

&:hover{
  border-color: var(--title-color);
}
`;


const Title = styled.h2`
  color: var(--Main-TitleColor, #0a2540);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.03rem;
  margin-top: 0.5rem;
`;

const Subtitle = styled.h3`
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02625rem;
  color: #666;
`;

const DialogStyled = styled(Dialog)`
  .p-dialog-header {
    padding:0;
  }

  .p-dialog-content {
    padding: 1.5rem 1.5rem 0;
  }

  .p-dialog-footer {
    padding: 1rem 1.5rem;
  }

  @media screen and (max-width: 768px) {
    max-width: 90vw;
  }
`;

const IconWrapper = styled.div<{color?:string}>`
  background: ${({color}) => color || "var(--primary-purple)"};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 8px;
  border-radius:4px;
  top:10px;
  right:-15px;

  svg{
    width: 22px;
    height: 22px;
    fill: white !important;


    path{
      fill:white !important;
    }
  }

`;

const ImageWrapper = styled.div`
position: relative;
img{
  width: 100px;
  height: 100px;
  border-radius: 12px;
}
`;

const AddManualContent: React.FC<Props> = ({ onHide, date }) => {
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);
  const [showPublishPostModal, setShowPublishPostModal] = useState<PostEntityType>();
  const {websiteId} = useParams();
  const appIntegrations = useRecoilValue(appIntegrationState(websiteId ?? ""));

  const filteredPlatforms = useMemo(() => {
    return Object.entries(NestedPostIcons)
      .map(([platform, iconData]) => {
        const integration = appIntegrations?.find(
          (integration) => integration.appIntegrationApplicationType === platform
        );
        return integration ? { platform, iconData, integration } : null;
      })
      .filter((item): item is { platform: string; iconData: typeof NestedPostIcons[string]; integration: typeof appIntegrations[number] } => Boolean(item));
  }, [appIntegrations]);
  

  return (
    <DialogStyled
      header={
        <>
     
        </>
      }
      modal={true}
      visible
      position={"center"}
      style={{
        width: "70vw",
        margin: "0",
        boxShadow: "none",
        borderRight: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
      }}
      onHide={onHide}
      draggable={false}
      resizable={false}
    >
      <ContentContainer>
      <Title className="m-auto">בחרו פלטפורמה</Title>  
      <PlatformContainer>
  {filteredPlatforms.map(({ platform, iconData, integration }) => (
    <PlatformButton 
      key={platform} 
      onClick={() => setSelectedPlatform(platform)}
      isSelected={selectedPlatform === platform}
    >
      <ImageWrapper>
        <img src={integration.imageUrl} alt="" />
        <IconWrapper color={iconData.color}>
          {iconData.iconFlat}
        </IconWrapper>
      </ImageWrapper>
      <span>{iconData.name}</span>
    </PlatformButton>
  ))}
</PlatformContainer>

        {selectedPlatform && (
          <>
<Divider />
<Title className="m-auto">בחרו סוג פוסט</Title>  
          <PostTypeContainer>
            {NestedPostIcons[selectedPlatform]?.postTypes.map((postType) => (
              <PlatformPostButton  key={postType.type} onClick={() => setShowPublishPostModal(postType.type)}>
                {postType.icon}
                <span>{postType.content}</span>
              </PlatformPostButton>
            ))}
          </PostTypeContainer>
          </>
        )}
      </ContentContainer>

      {showPublishPostModal && (
        <PublishPostModal
          postEntityType={showPublishPostModal}
          scheduleDate={date}
          onHide={() => onHide()}
        />
      )}
    </DialogStyled>
  );
};

export default AddManualContent;
