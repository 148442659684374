import React, { useEffect, useRef, useState } from "react";
import { ContentCardIcon, PostCardIcons } from "../post/postIconMappings";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../assets/Icons/Approved.svg";
import { ReactComponent as CalendarIcon } from "../../assets/Icons/Calendar.svg";
import { ReactComponent as FeedIcon } from "../../assets/Icons/Feed.svg";
import { ReactComponent as ExportIcon } from "../../assets/Icons/Download.svg";
import { ReactComponent as AddArticle } from "../../assets/Icons/AddArticle.svg";

import DropDownMenu from "../common/form/DropDownMenu";
import Button from "../common/form/Button";
import { PostEntityType } from "neword-core";
import AddManualContent from "../modals/AddManualContent";
import { useParams } from "react-router";

interface DropDownOption {
  label: string;
  icon?: React.ReactNode | JSX.Element;
  customTemplate?: React.ReactNode;
  link?: string;
  corners?:boolean;
}

interface CalendarMenuProps {
  setFilterPostType: React.Dispatch<React.SetStateAction<PostEntityType[]>>;
  filterPostType: PostEntityType[];
  toggleModal: () => void;
  notCalendar?: boolean;
  hideToggle?: boolean;
  // Define the props for your component here
}

interface ExtendContentCardIcon extends ContentCardIcon {
  type: string;
}

const Wrapper = styled.div<{ notCalendar?: boolean }>`
  width: 100%;

  z-index: 9;
  position: sticky;
  top: 0;
  background-color: white;
  border-radius: ${(props) => (props.notCalendar ? "12px" : "12px 12px 0 0 ")};
  border-bottom: ${(props) =>
    props.notCalendar ? "solid 1px var(--border-color)" : "none"};
  border: solid 1px var(--border-color);
  gap: 10px;
`;

const TopMenu = styled.div`
  z-index: 9999999;
  width: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 20px;
`;

const IconCheckBoxWrapper = styled.div<{ active?: boolean }>`
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
  border-color: ${(props) =>
    props.active ? "var(--border-color)" : "var(--border-color)"};
  svg {
    width: 15px;
    height: 15px;
  }

  cursor: pointer;
  &:hover {
    border-color: var(--primary-purple);
  }
`;

const CheckWrapper = styled.div<{ active?: boolean }>`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.active ? "var(--primary-purple)" : "transparent"};
  right: -5px;
  top: -5px;

  svg {
    fill: white;
    width: 6px;
    height: 6px;
  }
`;

const ButtonStyled = styled(Button)`
  height: 100%;
  padding: 10px 15px;
`;

const CalendarMenu: React.FC<CalendarMenuProps> = ({
  filterPostType,
  setFilterPostType,
  toggleModal, // Receive toggleModal as a prop
  hideToggle,
}) => {
  const SocialIcons = PostCardIcons;

  const [selectedOption, setSelectedOption] = useState<DropDownOption | null>(
    null
  );
  // Options to pass into DropDownMenu
  const options: DropDownOption[] = [
    {
      label: "תצוגת לוח שנה",
      icon: <CalendarIcon />,
      link: "/calendar",
    },
    {
      label: "תצוגת פיד",
      icon: <FeedIcon />,
      link: "/feed",
    },
    {
      label: "תצוגת גריד",
      icon: <FeedIcon />,
      link: "/grid",
    },
  ];

  // Match URL to default selection
  useEffect(() => {
    const currentPath = window.location.pathname.split("/").pop(); // Get the last part of the URL
    console.log("Current Path:", currentPath);

    const matchedOption = options.find((option) => {
      const path = option.link?.split("/").pop();
      return currentPath === path;
    });

    if (matchedOption) {
      setSelectedOption(matchedOption); // Set matched option
    }
  }, []);

  const handleSelect = (option: DropDownOption) => {
    setSelectedOption(option);
  };

  const groupedIcons = Object.entries(SocialIcons).reduce<
    Record<string, ExtendContentCardIcon[]>
  >((acc, [key, value]) => {
    if (value.scheduleAble) {
      acc[value.platform] = acc[value.platform] || [];
      acc[value.platform].push({ ...value, type: key });
    }
    return acc;
  }, {});

  return (
    <>
      <Wrapper notCalendar>
        <div className="grid m-0">
          <div className="col-3 m-auto">
            {hideToggle || (
              <div className="flex gap-10">
                <ButtonStyled
                  fullWidth={false}
                  icon={<AddArticle />}
                  primary
                  bgColor="pink"
                  onClick={toggleModal}
                >
                  צור תוכן ידני
                </ButtonStyled>
                <ButtonStyled icon={<ExportIcon />} bgColor="transparent">
                  ייצוא גאנט
                </ButtonStyled>
              </div>
            )}
          </div>
          <div className="col-6 m-auto">
            <TopMenu>
              {Object.entries(groupedIcons).map(([platform, icons]) => (
                <div key={platform}>
                  <div className="flex">
                    {
                      <IconCheckBoxWrapper
                        key={icons[0].type}
                        active={filterPostType.includes(
                          icons[0].type as PostEntityType
                        )}
                        onClick={() =>
                          filterPostType.includes(
                            icons[0].type as PostEntityType
                          )
                            ? setFilterPostType(
                                filterPostType.filter(
                                  (p) => !icons.some((icon) => icon.type === p)
                                )
                              )
                            : setFilterPostType((f) => [
                                ...f,
                                ...(icons.map(
                                  (icon) => icon.type
                                ) as PostEntityType[]),
                              ])
                        }
                      >
                        <CheckWrapper
                          active={filterPostType.includes(
                            icons[0].type as PostEntityType
                          )}
                        >
                          <CheckIcon />
                        </CheckWrapper>
                        {icons[0].icon}
                      </IconCheckBoxWrapper>
                    }
                  </div>
                </div>
              ))}
            </TopMenu>
          </div>
          <div className="col-3 m-auto">
            {hideToggle || (
              <DropDownMenu
                options={options}
                onSelect={handleSelect}
                selectedValue={selectedOption}
              />
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default CalendarMenu;
