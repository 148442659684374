import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup"; //Optional for grouping
import { useRecoilValue } from "recoil";
import { userState, usersState } from "../../state/userState";
import styled from "styled-components";
import React, { useMemo, useRef, useState } from "react";
//import { useRouter } from 'next/router';
import { Menu } from "primereact/menu";
import userService from "../../core/services/user.service";
import { ReactComponent as PlusIcon } from "../../assets/Icons/PlusBorder.svg";

import GWButton from "../common/form/Button";
import Link from "../common/Link";
import { Button } from "primereact/button";
import Announcement from "../common/Announcement";
import { announcementState } from "../../state/announcementState";
import WebsiteDropdownMenu from "../common/form/WebsitesDropdownMenu/WebsiteDropdownMenu";

import { ReactComponent as AddPerson } from "../../assets/Icons/AddPerson.svg";
import { ReactComponent as GlobeIcon } from "../../assets/Icons/Globe.svg";
import { ReactComponent as TeamIcon } from "../../assets/Icons/Team.svg";

import IconButton from "../common/IconButton";
import { Tooltip } from "primereact/tooltip";
import { Divider } from "primereact/divider";
import UpgradePackage from "../modals/UpgradePackage";
import useNavigator from "../../hooks/useNavigator";
import { PackageType } from "../../core/types/packageType";
import Badge from "../common/Badge";
import Protected from "../../middleware/protected";
import { UserRole } from "../../core/types/userRole";
import { Color } from "../../core/theme/types/color";

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  background: white;
  border-radius: 0px;
  min-height: 55px;
  border-bottom: 1px solid #e2e8f0;
`;

const AvatarStyled = styled(Avatar)`
  background-color: var(--yellow);
`;

const IconStyle = styled.i`
  font-size: 12px;
  transition-duration: 0.1s;
  padding-right: 8px;
`;
const ButtonAvatarStyled = styled(Button)`
  font-size: 14px;
  padding: 0;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: black !important;

  &:hover {
    ${IconStyle} {
      margin-top: 7px !important;
    }
  }
`;

const EmailText = styled.span`
  font-size: 14px;
  letter-spacing: -0.3px;
`;

const RoleText = styled.span`
  margin-top: 2px;
  font-size: 10px;
  letter-spacing: -0.3px;
`;

const AvatarContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: dashed 1px var(--border-color);
  border-radius: 50%; /* Makes the avatar circular */
  width: 2rem;
  height: 2rem;
  overflow: hidden;

  svg {
    width: 50%; /* Adjust icon size */
    height: 50%;
  }
`;

const ButtonStyled = styled(GWButton)`
  svg {
    fill: var(--primary-purple);
  }
  &:hover {
    background: var(--light-bg);
  }
`;

const TopMenu: React.FC = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigator();
  const menuLeft = useRef<Menu>(null);
  const [upgradePackageModal, setUpgradePackageModal] = useState(false);
  const isTrial = useMemo(
    () => user.company.Subscription.type === PackageType.TRIAL,
    [user.company.Subscription.type]
  );

  const users = useRecoilValue(usersState);

  let items = [
    {
      template: () => {
        return (
          <button
            onClick={(e) => navigate("/")}
            className="w-full p-link flex align-items-center p-3"
          >
            <div className="flex flex-column">
              <EmailText>{user?.email}</EmailText>
              <RoleText>{user?.role}</RoleText>
            </div>
            <AvatarStyled
              label={user?.email.at(0)?.toLocaleUpperCase()}
              shape="circle"
              className="mr-2"
            />
          </button>
        );
      },
    },
    { separator: true },

    {
      label: "הגדרות משתמש",

      command: () => {
        navigate(`/user-preferences/account-settings`);
      },
    },
    {
      label: "מסלולים וחיובים",

      command: () => {
        navigate(`/user-preferences/subscription-manage`);
      },
    },

    // {
    //   label: "Add Website",
    //   icon: "pi pi-fw pi-plus",
    //   command: () => {
    //     navigate("/websites/new");
    //   },
    // },
    {
      template: () => {
        return (
          <>
            <Divider className="m-0"></Divider>
          </>
        );
      },
    },
    {
      label: "התנתק",

      command: async () => {
        await userService.logout();
        document.location.href = "/login";
      },
    },
  ];

  const getColor = (role: UserRole): { bg: Color; text: Color } => {
    switch (role) {
      case UserRole.SUPER_ADMIN:
        return { bg: "purple", text: "bg" };

      case UserRole.ADMIN:
        return { bg: "pink", text: "bg" };

      case UserRole.EDITOR:
        return { bg: "yellow", text: "bg" };

      default:
        return { bg: "pink", text: "bg" };
    }
  };

  const announcementStateValue = useRecoilValue(announcementState);

  const teammates = useRecoilValue(usersState);

  return (
    <>
      <TopWrapper>
        <div className="flex gap-10">
          <WebsiteDropdownMenu className="changeBisness-step" />
          <Link path="/websites/new" global>
            <ButtonStyled icon={<PlusIcon />} textColor="purple">
              הוסף עסק חדש{" "}
            </ButtonStyled>
          </Link>
        </div>
        <div>
          <div className="flex align-items-center">
            <Protected neededRole={UserRole.EDITOR}>
              <Tooltip target=".add-person-icon" />
              <AvatarContainer
                path="/user-preferences/team"
                className="add-person-icon"
                data-pr-tooltip="הוסף חבר צוות/לקוח"
                data-pr-position="bottom"
              >
                <AddPerson />
              </AvatarContainer>
              <AvatarGroup style={{ direction: "ltr" }} className="ml-4">
                {teammates.slice(0, 4).map((teammate, index) => (
                  <>
                    <Tooltip target={`.teammate-${index}`}>
                      <div className="flex">
                        <div className="ml-1">
                          <strong>שם איש צוות: </strong>
                        </div>
                        {teammate.firstName + " " + teammate.lastName}
                      </div>
                      <div className="flex mt-2">
                        <div className="ml-1">
                          <strong>תפקיד בצוות: </strong>
                        </div>
                        <Badge
                          bgColor={getColor(teammate.role).bg}
                          textColor={getColor(teammate.role).text}
                        >
                          {teammate.role}
                        </Badge>
                      </div>
                    </Tooltip>
                    <AvatarStyled
                      key={index}
                      label={teammate.firstName.at(0)?.toLocaleUpperCase()} // Display the first letter of the email
                      shape="circle"
                      className={`teammate-${index}`} // Unique class for tooltip targeting
                      data-pr-position="bottom" // Tooltip position
                    />
                  </>
                ))}
                {teammates.length > 4 && (
                  <Avatar
                    label={`+${teammates.length - 4}`}
                    shape="circle"
                    size="normal"
                  />
                )}
              </AvatarGroup>

              {/* <Tooltip target=".plugins-icon" />
              <IconButton
                icon={<DownloadIcon />}
                width={"17"}
                className="ml-3 plugins-icon"
                path={`/plugins`}
                data-pr-tooltip="הורד תוספים רלוונטיים ל-CMS שלך"
                data-pr-position="bottom"
              /> */}
              <Tooltip target=".teams-icon" />
              <IconButton
                icon={<TeamIcon />}
                width={"17"}
                className="ml-3 teams-icon"
                path={`/user-preferences/team`}
                data-pr-tooltip="נהל את חברי הצוות שלך"
                data-pr-position="bottom"
              />
              <Tooltip target=".websites-icon" />
              <IconButton
                global
                icon={<GlobeIcon />}
                width={"17"}
                className="ml-3 websites-icon"
                path="/websites"
                data-pr-tooltip="כל העסקים"
                data-pr-position="bottom"
              />
            </Protected>

            <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
            <ButtonAvatarStyled
              iconPos="right"
              onClick={(event) => menuLeft.current?.toggle(event)}
              aria-controls="popup_menu_left"
              aria-haspopup
            >
              <AvatarStyled
                label={user?.firstName.at(0)?.toLocaleUpperCase()}
                shape="circle"
              />
              <IconStyle className="pi pi-angle-down"></IconStyle>
            </ButtonAvatarStyled>
          </div>
        </div>
      </TopWrapper>
      {announcementStateValue && (
        <Announcement>
          <div className="flex align-items-center">
            {announcementStateValue}
          </div>
        </Announcement>
      )}

      {upgradePackageModal && (
        <UpgradePackage onHide={() => setUpgradePackageModal(false)} />
      )}
    </>
  );
};

export default TopMenu;
