import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import ModalHeader from "../modals/ModalHeader";
import { ReactComponent as ScheduleIcon } from "../../assets/Icons/WebsiteIcons/Schedule.svg";
import { ReactComponent as PublishIcon } from "../../assets/Icons/Send.svg";

import { useCallback, useMemo, useRef, useState } from "react";
import Button from "../common/form/Button";

import { PostEntity, PostEntityType, PostSocialType } from "neword-core";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useController,
  useFieldArray,
  useForm,
} from "react-hook-form";

import { postTypeToForm } from "./forms/postTypeToForm";
import postService from "../../core/services/post.service";
import { useParams } from "react-router";
import { ReactComponent as PlusIcon } from "../../assets/Icons/Plus.svg";
import { ReactComponent as RemoveIcon } from "../../assets/Icons/Remove.svg";
import { Menu } from "primereact/menu";
import { Tooltip } from "primereact/tooltip";
import { PostCardIcons } from "./postIconMappings";
import { ZPostData } from "neword-core/dist/cjs/entities/post/postData";
import IconButton from "../common/IconButton";
import { createPostDeafultParams } from "./createPostDeafultParams";
import { useRecoilState, useRecoilValue } from "recoil";
import { postsSummariesState } from "../../state/postState";
import PostDateInput from "./PostDateInput";
import { toast } from "react-toastify";
import { appIntegrationState } from "../../state/appIntegrationState";
import { postTypeToappIntegrationApplicationType } from "./postTypeToappIntegrationApplicationType";
import useNavigator from "../../hooks/useNavigator";
import IntegrationsModal from "../modals/IntegrationsModal";
import ButtonWithDropdown from "../common/form/ButtonWithDropdown";

const DialogStyled = styled(Dialog)`
  direction: rtl;
  .p-dialog-content {
    padding: 0px;
    overflow: visible;
  }

  .p-dialog-header {
    padding: 0px !important;
  }

  .p-dialog-footer {
    padding: 10px !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

interface Props {
  postDefaults?: PostEntity["data"];
  postEntityType: PostEntityType;
  scheduleDate?: Date;
  onHide: () => void;
}

const IconWrapper = styled.div<{ isEditing: boolean }>`
  /* border: ${({ isEditing }) => (!isEditing ? "none" : "solid 2px  black")};

  border-radius: 8.5px; */
`;
const PlatformsWrapper = styled.div`
  border-left: 1px solid var(--border-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-top: 20px;
`;

const RemoveSocial = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: var(--title-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
`;

const PlatformIconWrapper = styled.div<{
  backgroundColor?: string;
  active?: boolean;
  dashed?: boolean;
  iconColor?: string;
}>`
  &:hover ${RemoveSocial} {
    opacity: 1;
    pointer-events: all;
  }

  &:hover {
    border-color: var(--primary-purple);
    svg path {
      fill: ${(props) =>
        props.iconColor
          ? props.iconColor
          : props.active
            ? "white !important"
            : "#9AA8B6 !important"};
    }
  }
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${(props) =>
    props.active ? props.backgroundColor : "var(--border-color)"};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.dashed ? "1px dashed lightgrey" : "none")};
  svg {
    width: 15px;
    height: 15px;
    fill: ${(props) =>
      props.iconColor
        ? props.iconColor
        : props.active
          ? "white !important"
          : "#9AA8B6 !important"};

    path {
      fill: ${(props) =>
        props.iconColor
          ? props.iconColor
          : props.active
            ? "white !important"
            : "#9AA8B6 !important"};
    }
  }
`;

const SocialWrapper = styled.div`
  width: 80px;
`;

const AddSocialButton = styled.div`
  cursor: pointer;
  &:hover {
    background: var(--light-bg);
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
`;

const ButtonLeftStyled = styled(Button)`
  border-radius: 12px 0px 0 12px;
  border-right: 1px solid var(--border-color);
`;

const ButtonRightStyled = styled(Button)`
  border-radius: 0px 12px 12px 0;
`;

const PublishPostModal: React.FC<Props> = ({
  onHide,
  postDefaults,
  scheduleDate,
  postEntityType,
}) => {
  const addSocialMenu = useRef<Menu>(null);
  const { websiteId } = useParams();
  const appIntegrations = useRecoilValue(
    appIntegrationState(websiteId as string)
  );
  const [isLoading, setIsLoading] = useState(false);

  const [showIntegrationModal, setShowIntegrationPostModal] =
    useState<boolean>();

  const [postsSummaries, setPostsSummaries] = useRecoilState(
    postsSummariesState(websiteId as string)
  );

  const navigate = useNavigator();

  const methods = useForm<{ posts: PostEntity[]; scheduleDate: Date }>({
    defaultValues: {
      posts: [
        {
          scheduleDate: scheduleDate,
          type: postEntityType,
          data: postDefaults ?? createPostDeafultParams(postEntityType),
        },
      ],
      scheduleDate: scheduleDate,
    },
  });

  const posts = useFieldArray({ control: methods.control, name: "posts" });

  const [postType, setPostType] = useState(postEntityType);

  const getTypeIndex = useCallback(
    (type: PostEntityType) =>
      posts.fields.findIndex((post) => post.type === type),
    [posts]
  );

  const postIndex = useMemo(
    () => getTypeIndex(postType),
    [posts.fields, postType]
  );

  const FormComponent =
    postTypeToForm[methods.getValues(`posts.${postIndex}.data.type`)];

  const isMissingIntegration = useMemo(
    () =>
      posts.fields
        .map((p) => {
          return appIntegrations.find(
            (integration) =>
              integration.appIntegrationApplicationType ===
                postTypeToappIntegrationApplicationType(
                  p.type as PostEntityType
                ) && integration.isEnabled
          );
        })
        .includes(undefined),
    [appIntegrations, posts]
  );

  const handleSubmit: SubmitHandler<{
    posts: PostEntity[];
  }> = async (values) => {
    if (isMissingIntegration) {
      setShowIntegrationPostModal(true);
    } else {
      const allScheduleDate = methods.getValues("scheduleDate");
      try {
        setIsLoading(true);
        await postService.schedulePost(
          values.posts.map((p) => ({ ...p, scheduleDate: allScheduleDate })),
          websiteId as string
        );
        toast("הפוסט נוצר בהצלחה", {
          type: "success",
        });
        setPostsSummaries(
          await postService.getPostsByWebsiteId(websiteId as string)
        );

        navigate(`/calendar`);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
      onHide();
    }
  };

  const handlePublishSubmit: SubmitHandler<{
    posts: PostEntity[];
  }> = useCallback(
    async (values) => {
      //if (isMissingIntegration) return;
      if (isMissingIntegration) {
        setShowIntegrationPostModal(true);
      } else {
        try {
          setIsLoading(true);
          await postService.schedulePost(
            values.posts,
            websiteId as string,
            true
          );
          toast("הפוסט פורסם בהצלחה", {
            type: "success",
          });
          setPostsSummaries(
            await postService.getPostsByWebsiteId(websiteId as string)
          );

          navigate(`/calendar`);
        } catch (err) {
        } finally {
          setIsLoading(false);
        }
        onHide();
      }
    },
    [isMissingIntegration]
  );

  const onClickIcon = (type: PostEntityType) => {
    if (getTypeIndex(type) < 0) {
      const postData = createPostDeafultParams(type);
      const currentPost = posts.fields[postIndex];

      const newPost: PostEntity = {
        ...currentPost,
        data: {
          ...postData,
          message: currentPost.data.message,
          imageUrls: currentPost.data.imageUrls,
        },
        type,
      };

      posts.append(newPost);
    }
    setPostType(type);
  };

  let menuitems = Object.entries(PostCardIcons)
    .filter(
      ([key, value]) =>
        !!value.iconPlain && getTypeIndex(key as PostEntityType) < 0
    )
    .map(([key, value]) => ({
      template: () => (
        <AddSocialButton
          onClick={() => onClickIcon(key as PostEntityType)}
          className="p-3 flex gap-10  align-items-center"
        >
          <PlatformIconWrapper backgroundColor={value.color} active>
            {value.iconPlain && <value.iconPlain />}
          </PlatformIconWrapper>
          {value.socialPostingText && value.socialPostingText}
        </AddSocialButton>
      ),
    }));

  const handleRemovePost = useCallback(
    (typeToRemove: PostEntityType) => {
      const indexToRemove = getTypeIndex(typeToRemove);

      if (posts.fields.length === 1) {
        onHide();
        return;
      }

      if (typeToRemove === postType) {
        const nextPost = posts.fields.find(
          (field) => field.type !== typeToRemove
        );
        if (nextPost) {
          setPostType(nextPost.type as PostEntityType);
        }
      }

      posts.remove(indexToRemove);
    },
    [posts, postType]
  );

  return (
    <FormProvider {...methods}>
      <form>
        <DialogStyled
          header={<ModalHeader OnClose={onHide} downAbit />}
          closable={false}
          visible
          position={"center"}
          style={{
            width: "80vw",
            margin: "0",
            boxShadow: "none",

            borderLeft: "solid 1px var(--border-color)",
            borderRadius: "0px",
            maxHeight: "100%",
            padding: "0",
          }}
          onHide={onHide}
          draggable={false}
          resizable={false}
          footer={
            <div className="grid m-0">
              <ButtonsWrapper className="col-9 gap-0">
                <ButtonWithDropdown
                  options={[
                    {
                      label: "פרסם עכשיו",
                      onClick: methods.handleSubmit(handlePublishSubmit),
                      icon: <PublishIcon />,
                    },
                  ]}
                  loading={isLoading}
                  primary
                  onClick={methods.handleSubmit(handleSubmit)}
                  icon={<ScheduleIcon />}
                  bgColor="purple"
                  textColor="white"
                >
                  תזמון פוסט
                </ButtonWithDropdown>

                {/* {isMissingIntegration && <span>חסר אינטגרציה </span>} */}
                <div className="mr-3">
                  <PostDateInput />
                </div>
              </ButtonsWrapper>

              <div className="col-3"></div>
            </div>
          }
        >
          <div className="flex m-0 h-full">
            <Tooltip target=".remove-icon" />
            <Menu model={menuitems} popup ref={addSocialMenu} id="socialmenu" />
            <SocialWrapper>
              <PlatformsWrapper>
                {Object.entries(PostCardIcons)
                  .filter(
                    ([key, value]) =>
                      !!value.iconPlain &&
                      getTypeIndex(key as PostEntityType) > -1
                  ) // Filter out items that don't have an iconPlain
                  .map(([key, value]) => {
                    const isActive = getTypeIndex(key as PostEntityType);

                    return (
                      <IconWrapper
                        onClick={() => onClickIcon(key as PostEntityType)}
                        isEditing={isActive === postIndex}
                      >
                        <PlatformIconWrapper
                          active={isActive === postIndex}
                          backgroundColor={value.color}
                        >
                          {isActive > -1 && (
                            <RemoveSocial
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemovePost(key as PostEntityType);
                              }}
                              className="remove-icon"
                              data-pr-tooltip="הסר פלטפורמה מפרסום"
                              data-pr-position="right"
                            >
                              <RemoveIcon />
                            </RemoveSocial>
                          )}

                          <IconButton
                            icon={value.iconPlain && <value.iconPlain />}
                          />
                        </PlatformIconWrapper>
                      </IconWrapper>
                    );
                  })}

                <PlatformIconWrapper
                  backgroundColor="white"
                  active
                  dashed
                  iconColor="lightgrey"
                  onClick={(event) => addSocialMenu.current?.toggle(event)}
                  aria-controls="socialmenu"
                  aria-haspopup
                >
                  <PlusIcon />
                </PlatformIconWrapper>
              </PlatformsWrapper>
            </SocialWrapper>
            <Wrapper>
              {FormComponent ? (
                <FormComponent
                  name={`posts.${postIndex}.data`}
                  index={postIndex}
                />
              ) : (
                <div>no form</div>
              )}{" "}
            </Wrapper>
          </div>
        </DialogStyled>
      </form>
      {showIntegrationModal && (
        <IntegrationsModal
          onSuccess={() => {}}
          onHide={() => setShowIntegrationPostModal(false)}
        />
      )}
    </FormProvider>
  );
};

export default PublishPostModal;
