import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as WandIcon } from "../../../assets/Icons/Wand.svg";
import { ReactComponent as HomeIcon } from "../../../assets/Icons/Home.svg";
import { ReactComponent as MediaIcon } from "../../../assets/Icons/AiImage.svg";
import CreateDocModal from "../../common/form/CreateDocModal";
import { Divider } from "primereact/divider";
import Link from "../../common/Link";
import GenerateMediaModal from "../../modals/generateMediaModal";

const Wrapper = styled.div`
  width: 60px;
  border-left: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    fill: var(--title-color);
    width: 17px;
    height: 17px;
  }

  &:hover {
    border: solid 1px var(--title-color);
    background-color: var(--light-bg);
    border-radius: 5px;
  }
`;

const HomeWrapper = styled.div`
  width: 35px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    fill: var(--title-color);
    width: 17px;
    height: 17px;
  }

  &:hover {
    background-color: var(--light-bg);
    border-radius: 5px;
  }
`;

const DocumentSideBar: React.FC = () => {
  const [showCreateDocModal, setShowCreateDocModal] = useState(false);
  const [showGenerateMediaModal, setShowGenerateMediaModal] = useState(false);

  return (
    <>
      <Wrapper className="col-2">
        <Link path="/">
          <HomeWrapper>
            <HomeIcon />
          </HomeWrapper>
        </Link>
        <Divider className="m-0" />
        <IconWrapper onClick={() => setShowCreateDocModal(true)}>
          <WandIcon />
          <small>צור חדש</small>
        </IconWrapper>

        <IconWrapper onClick={() => setShowGenerateMediaModal(true)}>
          <MediaIcon />
          <small>צור מדיה</small>
        </IconWrapper>
      </Wrapper>

      {showCreateDocModal && (
        <CreateDocModal onHide={() => setShowCreateDocModal(false)} />
      )}

      {showGenerateMediaModal && (
        <GenerateMediaModal onHide={() => setShowGenerateMediaModal(false)} />
      )}
    </>
  );
};

export default DocumentSideBar;
