import { AttachmentMedia } from "neword-core";

export default (value: AttachmentMedia[]) => {
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  const images = value.filter((item) => item.metadata.type === "IMAGE");
  let errorText = "";

  // Basic validation for videos and images
  if (videos.length > 1) {
    errorText = "מותר לבחור וידאו אחד בלבד";
  }
  if (images.length > 0) {
    errorText = "מותר לבחור רק וידיאו";
  }

  // Validation for Facebook Reels metadata

  return errorText;
};
