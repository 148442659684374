import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { toursFinishedInSetionState, tourState } from "../state/tourState";
import userService from "../core/services/user.service";
import { userState } from "../state/userState";
import { Tour } from "../core/types/tourType";
import { TourNames } from "../core/types/tourNames";
import { useCallback } from "react";

export const useTour = () => {
  const [currentTour, setTour] = useRecoilState(tourState);
  const [toursFinishedInSetion, setToursFinishedInSetion] = useRecoilState(
    toursFinishedInSetionState
  );
  const userSelector = useRecoilValueLoadable(userState);

  const startTour = useCallback(
    async (tour: Tour) => {
      if (userSelector.state === "hasValue") {
        const user = userSelector.getValue();

        if (
          user.completedTours.includes(tour.name) ||
          toursFinishedInSetion.includes(tour.name)
        ) {
          setTour({
            steps: [],
            isRunning: false,
            name: TourNames.NoActiveTour,
          });
          return;
        }

        setTour({
          name: tour.name,
          steps: [...tour.steps],
          isRunning: true,
        });
      }
    },
    [setTour, toursFinishedInSetion, userSelector]
  );

  const stopTour = useCallback(
    async (tourName: TourNames) => {
      setTour({
        name: TourNames.NoActiveTour,
        steps: [],
        isRunning: false,
      });
      setToursFinishedInSetion((prev) => [...prev, tourName]);
      try {
        await userService.finishUserTour(tourName);
      } catch (err) {
        console.log(err);
      }
    },
    [setTour, setToursFinishedInSetion]
  );

  return {
    tour: currentTour,
    startTour,
    stopTour,
  };
};
