import { Divider } from "primereact/divider";
import { InputTextarea } from "primereact/inputtextarea";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import CardTitle from "../../common/CardTitle";
import MultyAttachmentInput from "../../common/form/MultyAttachmentInput";
import TiktokPreview from "../postPreviews/TiktokPreview";
import tiktokPostMediaValidation from "./mediaValdiation/tiktokPostMediaValidation";
import CircleBg from "../../../assets/images/bgcirclepurple.png";
import Card from "../../common/Card";
import { Message } from "primereact/message";
import MediaEditor from "../MediaEditor";
import { AttachmentMedia } from "neword-core";
import tiktokPostMediaWarning from "./mediaValdiation/tiktokPostMediaWarning";

const InputTextareaStyled = styled(InputTextarea)`
  outline: none !important;
  min-height: fit-content;
  height: auto;
  width: 100%;
  border: none;
  resize: none; /* Optional: prevent manual resizing */
  &::placeholder {
    color: var(--text-color);
    opacity: 0.5;
  }

  &:hover {
    background: var(--light-bg);
  }
  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    background: var(--light-bg);
    border-color: none !important;
  }
`;

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background-size: cover;
  position: relative;
  background-color: black;
  margin-left: -1px;
  margin-top: -1px;
  &::before {
    content: "";
    position: absolute;
    top: 0x;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url(${CircleBg});
    background-size: cover;
    opacity: 0.15; /* Adjust the opacity as needed */
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
  }
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
`;

function TiktokPostForm<FormDataType extends FieldValues>({
  name,
  index,
}: {
  name: Path<FormDataType>;
  index?: number;
}) {
  const {
    control,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const values = useWatch({ control, name: name }); // Ensure posts has a default value of an empty array
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrlError, setImageUrlError] = useState("");
  const [mediaToEdit, setMediaToEdit] = useState<AttachmentMedia | null>(null);

  // Function to automatically adjust textarea height
  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };
  const warningMediaValidation = tiktokPostMediaWarning(values.imageUrls);

  // Run auto-resize on mount and when posts change
  useEffect(() => {
    autoResizeTextarea();
  }, [values]);

  return (
    <div className="grid h-full m-0">
      <div className="col-8 p-0 pl-4 pr-4 pt-4">
        <CardTitle title="פרסום פוסט באינסטגרם" />
        <Divider />
        <Card>
          <LabelStyled>תוכן הפוסט</LabelStyled>
          <InputTextareaStyled
            {...register(`${name}.message`)}
            className="w-90 pl-3"
            placeholder="רשמו משהו, בלה בלה תיאור לפוסט"
            onInput={autoResizeTextarea}
            ref={(e) => {
              register(`${name}.message`).ref(e);
              textareaRef.current = e;
            }}
          />
          <Divider />
          <LabelStyled>הוספת מדיה</LabelStyled>
          <MultyAttachmentInput
            name={`${name}.imageUrls`}
            validate={(value) => {
              const errorText = tiktokPostMediaValidation(value);
              setImageUrlError(errorText);
              if (errorText === "") {
                return true;
              }
              return errorText;
            }}
            onAddMedia={(media) => {
              setMediaToEdit(media);
            }}
          />

          {/* {mediaToEdit ? (
            <MediaEditor
              url={mediaToEdit.url}
              onProcess={(attachment: AttachmentMedia) =>
                setValue(
                  name + ".imageUrls",
                  values.imageUrls.map((m: AttachmentMedia) =>
                    m.url === mediaToEdit.url ? attachment : m
                  )
                )
              }
              imageSettings={{
                imageCropAspectRatio: 9 / 16, // Maintain TikTok's vertical aspect ratio
              }}
              imageWriterSettings={{
                targetSize: {
                  width: 1080, // TikTok preferred width
                  height: 1920, // TikTok preferred height
                },
              }}
              videoSettings={{
                framesPerSecond: 30, // TikTok-compatible frame rate
                videoBitrate: 2500000, // 2.5 Mbps for HD videos
              }}
              onClose={() => setMediaToEdit(null)}
            />
          ) : (
            <></>
          )} */}
        </Card>
        <div className="media p-2 mt-4">
          {imageUrlError != "" && (
            <Message severity="error" text={imageUrlError} />
          )}
        </div>
        <div className="media p-2">
          {warningMediaValidation != "" && (
            <Message severity="warn" text={warningMediaValidation} />
          )}
        </div>
      </div>
      <div className="col-4 p-0">
        <ImageWrapper className="p-4">
          <TiktokPreview reel={true} name={name} />
        </ImageWrapper>
      </div>
    </div>
  );
}

export default TiktokPostForm;
