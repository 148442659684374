import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import FormStyle from "../../../common/form/FormStyle";
import { ReactComponent as FacebookIcon } from "../../../../assets/Icons/FacebookIconFlat.svg";
import { ReactComponent as InstagramIcon } from "../../../../assets/Icons/InstagramIconFlat.svg";
import { ReactComponent as LinkedinIcon } from "../../../../assets/Icons/LinkedinIconFlat.svg";
import { ReactComponent as TiktokIcon } from "../../../../assets/Icons/TiktokLogo.svg";
import { ReactComponent as XIcon } from "../../../../assets/Icons/XLogo.svg";
import Button from "../../../common/form/Button";
import bgImage from "../../../../assets/images/facebookPreview.png";
import { useRecoilState } from "recoil";
import { appIntegrationState } from "../../../../state/appIntegrationState";
import { useFormContext } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../../../core/services/requests/createWebsite/createWebsiteRequestData";
import { AppIntegrationApplicationType } from "../../../../core/types/appIntegrationApplicationType";
import useIntegrations from "../../../../hooks/useIntegrations";
import FacebookPagesModal from "../../../common/form/FacebookPagesModal";
import Badge from "../../../common/Badge";
import { choseLoginMethod } from "../../../modals/InstagramIntegrationModal";

const ButtonStyled = styled(Button)`
  transition: all 0.3s ease;
`;

// Styling for the container
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

// Styling for individual items
// Styling for individual items
const IntegrationItem = styled.div<{ color: string; connected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: ${({ connected, color }) =>
    connected ? `1px solid ${color}` : "1px dashed #ccc"};
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: ${({ connected, color }) =>
    connected ? "#fff" : "#fff"}; /* Ensure background remains white */

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 6px;
  }

  .icon svg {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    transition: all 0.3s ease;
    fill: ${({ color }) => color} !important;

    path {
      fill: ${({ connected }) => (connected ? "#fff" : "inherit")} !important;
    }
  }

  span {
    font-size: 14px;
    color: ${({ connected }) => (connected ? "#333" : "#333")};
    margin-bottom: 5px;
    transition: all 0.3s ease;
  }

  p {
    font-size: 12px;
    color: ${({ connected }) => (connected ? "#333" : "#666")};
    margin: 0;
    transition: all 0.3s ease;
  }

  ${ButtonStyled} {
    pointer-events: ${({ connected }) => (connected ? "auto" : "auto")};
    color: ${({ connected }) => (connected ? "#333" : "#333")};
    transition: color 0.3s ease;
  }

  &:hover {
    background-color: ${({ connected, color }) => (connected ? "#fff" : color)};
    border-color: ${({ color }) => color};

    .icon path {
      fill: ${({ connected }) => (connected ? "#fff" : "#fff")} !important;
    }

    span,
    p {
      color: ${({ connected }) => (connected ? "#333" : "#fff")};
    }

    ${ButtonStyled} {
      color: ${({ connected }) => (connected ? "red" : "#fff")};
    }
  }
`;

const ProfileWrapper = styled.div`
  position: relative;
`;

const FloatingIcon = styled.div<{ color: string }>`
  position: absolute;
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  top: -7px;
  right: -7px;
  background-color: ${({ color }) => color};
  svg {
    fill: #fff;
    width: 15px;
    height: 15px;
  }
`;

// Wrapper to add overall form styles
const FormStyleStyled = styled(FormStyle)`
  margin-top: 60px;
  margin-bottom: 60px;
  width: 100%;
  max-width: 800px;
`;

// Platform data
type Platform = {
  name: string;
  icon: JSX.Element;
  color: string;
  disabled?: boolean;
  type: AppIntegrationApplicationType;
};
export const platforms: Platform[] = [
  {
    name: "פייסבוק",
    icon: <FacebookIcon />,
    color: "#3b5998",
    type: AppIntegrationApplicationType.FACEBOOK,
  },
  {
    name: "אינסטגרם",
    icon: <InstagramIcon />,
    color: "#e4405f",
    type: AppIntegrationApplicationType.INSTAGRAM,
  },
  {
    name: "טיקטוק",
    icon: <TiktokIcon />,
    color: "#010101",
    type: AppIntegrationApplicationType.TIKTOK,
  },
  {
    name: "לינקדאין",
    icon: <LinkedinIcon />,
    color: "#0077b5",
    type: AppIntegrationApplicationType.LINKEDIN,
  },
  {
    name: "X (טוויטר)",
    icon: <XIcon />,
    color: "black",
    type: AppIntegrationApplicationType.X,
  },
];

const Integrations: React.FC = () => {
  const methods = useFormContext<CreateWebsiteRequestData>();
  const websiteId = methods.getValues().website.id;

  const [appIntegrations, setAppIntegrations] = useRecoilState(
    appIntegrationState(websiteId)
  );

  const {
    facebookPages,
    showFacebookModal,
    getIntegrationToggle,
    setShowFacebookModal,
    instagramWithInstagramToggle,
  } = useIntegrations(websiteId);

  const platformsWithAppIntegrations = useMemo(() => {
    return platforms.map((platform) => {
      const appIntegration = appIntegrations.find(
        (appIntegration) =>
          appIntegration.appIntegrationApplicationType === platform.type
      );
      return {
        ...platform,
        appIntegration,
      };
    });
  }, [appIntegrations]);

  useEffect(() => {
    methods.setValue("appIntegrationsCounter", appIntegrations.length);
  }, [appIntegrations]);

  return (
    <FormStyleStyled>
      <GridContainer>
        {platformsWithAppIntegrations.map((platform) => (
          <IntegrationItem
            key={platform.name}
            color={platform.color}
            connected={!!platform.appIntegration?.isEnabled}
            onClick={async () => {
              if (platform.disabled) return; // Prevent interaction if disabled
              console.log("asdasdasd");
              let change = getIntegrationToggle[platform.type];

              if (
                !platform.appIntegration?.isEnabled &&
                platform.type === AppIntegrationApplicationType.INSTAGRAM
              ) {
                const choosenLoginMethod = await choseLoginMethod({} as any);
                if (choosenLoginMethod === "instagram") {
                  change = instagramWithInstagramToggle;
                }
                if (choosenLoginMethod === "cancel") return;
              }
              if (change) {
                change(!platform.appIntegration?.isEnabled);
              }
            }}
            style={
              platform.disabled ? { opacity: 0.7, cursor: "not-allowed" } : {}
            }
          >
            {platform.appIntegration?.isEnabled ? (
              <ProfileWrapper className="position-relative">
                <img src={platform.appIntegration.imageUrl} alt="" />
                <FloatingIcon color={platform.color} className="floatingicon">
                  {platform.icon}
                </FloatingIcon>
              </ProfileWrapper>
            ) : (
              <div className="icon">{platform.icon}</div>
            )}
            {platform.appIntegration?.isEnabled ? (
              <strong>{platform.appIntegration.name}</strong>
            ) : (
              <span>{platform.name}</span>
            )}
            <ButtonStyled
              bgColor="transparent"
              className="p-0 mt-2"
              arrowPlacement={
                platform.appIntegration?.isEnabled ? "none" : "right"
              }
              disabled={platform.disabled} // Disable the button
            >
              {platform.disabled
                ? "בקרוב"
                : platform.appIntegration?.isEnabled
                  ? "התנתק"
                  : "התחברות"}
            </ButtonStyled>
          </IntegrationItem>
        ))}
      </GridContainer>
      {showFacebookModal.open && (
        <FacebookPagesModal
          websiteIdParam={websiteId}
          isInstagram={
            showFacebookModal.type === AppIntegrationApplicationType.INSTAGRAM
          }
          onClose={() =>
            setShowFacebookModal((p) => ({ open: false, type: p.type }))
          }
          pages={facebookPages}
        />
      )}
    </FormStyleStyled>
  );
};

export default Integrations;
