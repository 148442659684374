import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as Edit } from "../../../assets/Icons/EditImage.svg";
import { ReactComponent as Remove } from "../../../assets/Icons/Times.svg";
import { ReactComponent as Comment } from "../../../assets/Icons/facebook/FacebookComment.svg";
import { ReactComponent as Like } from "../../../assets/Icons/facebook/FacebookLike.svg";
import { ReactComponent as Share } from "../../../assets/Icons/facebook/FacebookShare.svg";

import Card from "../../common/Card";
import {
  FieldValues,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { AttachmentMedia } from "neword-core";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import FacebookPreview from "../../../assets/images/facebookPreview.png"; // Add Facebook preview image
import { Galleria } from "primereact/galleria";
import MediaEditor from "../MediaEditor";
import { appIntegrationState } from "../../../state/appIntegrationState";

const Wrapper = styled(Card)<{ reel?: boolean }>`
  position: relative;
  padding: 0px;
  width: 100%;
  min-height: 400px;
  max-width: ${({ reel }) => (reel ? "300px" : "300px")};
  border: none;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 10px;
  padding: 10px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

const AccountName = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-family: "Helvetica", "Arial", sans-serif;
`;

const PostTime = styled.div`
  font-size: 12px;
  color: #65676b;
`;

const ContentWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  padding: 0 10px;
`;

const SyledGalleria = styled(Galleria)`
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  min-height: 230px;

  .p-galleria-item {
    background-color: black;
    border-radius: 8px;
  }

  .p-galleria-item-next {
    right: auto !important;
    left: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-item-prev {
    left: auto !important;
    right: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-indicator button {
    width: 0.5rem !important;
    height: 0.5rem !important;
  }

  .p-galleria-indicators {
    padding: 0 !important;
  }

  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: var(--primary-purple);
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 1 / 1;
    border-radius: 0px;
  }
`;

const PostImage = styled.img`
  aspect-ratio: 1 / 1;
  border-radius: 0px;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 99;
`;

const ToolWrapper = styled.div`
  gap: 10px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: white;
  }
  display: flex;
  height: 40px;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 6px;
  transition: background-color 0.2s;
  z-index: 99999;
  svg {
    fill: white;
    width: 20px;
    height: 20px;
  }

  path {
    fill: white;
  }

  &:hover {
    background-color: #2c2c2c;
  }
`;

const IconItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  color: #65676b;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
`;

const FakeIconsWrapepr = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
`;

const Tools = styled.div`
  gap: 10px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
`;
function FbPreview<FormDataType extends FieldValues>({
  name,
  reel = false, // Default is false
}: {
  name: Path<FormDataType>;
  reel?: boolean;
}) {
  const methods = useFormContext();
  const { websiteId } = useParams();
  const website = useRecoilValue(websiteState(websiteId as string));
  const values = useWatch({
    control: methods.control,
    name: name,
  }); // Ensure posts has a default value
  const [showMediaEditorModal, setMediaEditorModal] = useState(false);

  // UseMemo to calculate imageUrl based on the current posts array and postIndex
  const imageUrl = useMemo<AttachmentMedia[]>(() => {
    return values?.imageUrls || FacebookPreview;
  }, [values]);

  const appIntegrations = useRecoilValue(appIntegrationState(websiteId ?? ""));

  const getIntegration = (platform: string) => {
    return appIntegrations?.find(
      (integration) => integration.appIntegrationApplicationType === platform
    );
  };

  // Example usage:
  const facebookIntegration = getIntegration("FACEBOOK");

  return (
    <Wrapper reel={reel}>
      <TopSection>
        <img src={facebookIntegration?.imageUrl} alt="" />
        <div className="flex flex-column ">
          <AccountName>{website?.name}</AccountName>
          <PostTime>Just now</PostTime>
        </div>
      </TopSection>
      <ContentWrapper>{values && values.message}</ContentWrapper>

      <div className="pos-rel"></div>
      <SyledGalleria
        value={
          imageUrl.length > 0
            ? imageUrl
            : [{ url: FacebookPreview, metadata: { type: "IMAGE" } }]
        }
        showThumbnails={false}
        showIndicators={false}
        showItemNavigators
        item={(item: AttachmentMedia) => {
          if (!item) return;

          return (
            <>
              <Tools>
                <ToolWrapper
                  onClick={() =>
                    methods.setValue(
                      name + ".imageUrls",
                      values.imageUrls.filter(
                        (m: AttachmentMedia) => m !== item
                      )
                    )
                  }
                >
                  <Remove />
                  <span>הסר</span>
                </ToolWrapper>
                <ToolWrapper onClick={() => setMediaEditorModal(true)}>
                  <Edit />
                  <span>עריכת תמונה/וידאו</span>
                </ToolWrapper>
              </Tools>

              {item.metadata.type === "VIDEO" ? (
                <video
                  src={item.url}
                  controls
                  style={{
                    maxWidth: reel ? "300px" : "auto",
                    aspectRatio: reel ? "9/16" : "1/1",
                  }}
                />
              ) : (
                <PostImage src={item.url} />
              )}
              {showMediaEditorModal && (
                <MediaEditor
                  url={item.url}
                  onProcess={(attachment: AttachmentMedia) =>
                    methods.setValue(
                      name + ".imageUrls",
                      values.imageUrls.map((m: AttachmentMedia) =>
                        m === item ? attachment : m
                      )
                    )
                  }
                  onClose={() => setMediaEditorModal(false)}
                />
              )}
            </>
          );
        }}
      />
      <FakeIconsWrapepr>
        <IconItem>
          <Like />
          לייק
        </IconItem>
        <IconItem>
          <Comment />
          תגובה
        </IconItem>

        <IconItem>
          <Share />
          שיתוף
        </IconItem>
      </FakeIconsWrapepr>

      {/* <IconWrapper>
        <Logo width="20" height="20" />
      </IconWrapper> */}
    </Wrapper>
  );
}

export default FbPreview;
