import { DocumentToneType } from 'neword-core';

export const tonePromptFactory: Record<DocumentToneType, string> = {
  MARKETING: `
  - צור פוסט שיווקי שמטרתו הנעה לפעולה.
  - השתמש בכותרת מושכת שמעוררת סקרנות או צורך.
  - הצג את המוצר או השירות בצורה משכנעת – הדגש יתרונות ולא תכונות.
  - ודא שהטקסט כולל קריאה ברורה לפעולה (CTA) כמו "הצטרפו עכשיו", "קבלו הצעה מיוחדת".
  - כתוב בטון שמרגיש קרוב לקהל, אך גם מקצועי ואמין.
  - דוגמה: "רוצים להגדיל את החשיפה של העסק שלכם פי 3? 🚀 הצטרפו עכשיו וגלו איך!"
    - שימוש בשפה מדוברת יומיומית שמתאימה לקהל ישראלי!

  `,

  PERSUASIVE: `
  - צור פוסט שמטרתו לשכנע את הקהל לנקוט בפעולה או לשנות תפיסה.
  - התחל בטיעון חזק או בשאלה רטורית כדי לעורר מחשבה.
  - השתמש בהוכחות חברתיות (עדויות, סיפורי לקוחות, מחקרים).
  - סיים בקריאה לפעולה חכמה שמעודדת את הקהל להתנסות בעצמם.
  - דוגמה: "90% מהעסקים שמנסים את השיטה שלנו רואים תוצאות בתוך 30 יום. מוכנים להצטרף?"
    - שימוש בשפה מדוברת יומיומית שמתאימה לקהל ישראלי!

  `,

  INFORMATIVE: `
  - כתוב פוסט מבוסס-עובדות עם מידע ברור ומדויק.
  - התחל עם עובדה מעניינת או שאלה מעוררת עניין.
  - הצג את המידע בצורה נגישה – שימוש בנתונים, מחקרים, אינפוגרפיקות.
  - שמור על טון מקצועי אך קריא, לא אקדמי מדי.
  - דוגמה: "הידעתם? 🧠 מחקרים מראים שכתיבה שיווקית עם הנעה לפעולה מגדילה המרות ב-30%!"
    - שימוש בשפה מדוברת יומיומית שמתאימה לקהל ישראלי!

  `,

  FRIENDLY: `
  - כתוב בטון נעים, משוחרר ואישי.
  - התחל בפנייה ישירה ("היי חברים!", "נו מה אתם אומרים?").
  - שמור על שפה קולחת עם משפטים קצרים, אפשר לשלב סלנג עדין.
  - התייחס לקוראים כאילו הם חברים, תוך שמירה על ענייניות.
  - דוגמה: "יאללה, סוף סוף הגיעה ההזדמנות! 😍 אתם הולכים לעוף על זה!"
    - שימוש בשפה מדוברת יומיומית שמתאימה לקהל ישראלי!

  `,

  INSPIRED: `
  - צור פוסט שמעורר השראה ומעודד פעולה חיובית.
  - השתמש במשפטים שמעודדים מוטיבציה ("תזכרו – כל מסע מתחיל בצעד אחד").
  - הוסף סיפור קצר או דוגמה שנותנת השראה.
  - דוגמה: "כל חלום מתחיל ברגע אחד של אומץ. היום זה הרגע שלכם!"
    - שימוש בשפה מדוברת יומיומית שמתאימה לקהל ישראלי!

  `,

  HUMOROUS: `
  - כתוב בטון הומוריסטי, שנון וכיפי.
  - תרשום בצורה של MEME של הדור החדש והמצחיק
  - שלב משחקי מילים, הגזמות מצחיקות או רפרנסים תרבותיים.
  - הימנע מהומור מורכב מדי כדי שיתאים לקהל רחב.
  - אין בעיה להגזים, העיקר שיהיה הגיון מאחורי המילים
  - דוגמה: "אמרו לנו לכתוב פוסט שיווקי. אז הנה: קנו אותנו עכשיו ותקבלו חיוך חינם! 😜"
    - שימוש בשפה מדוברת יומיומית שמתאימה לקהל ישראלי!

  `,

  TRENDY_PROVOCATIVE: `
  - השתמש בשפה עכשווית, ביטויים עדכניים ורפרנסים לתרבות הרשת.
  - כתוב בצורה שמייצרת מעורבות – שאלות פתוחות, הצהרות חזקות.
  - אל תפחד להיות קצת בועט או לעורר דיון, אבל אל תחרוג מגבולות המותג.
  - דוגמה: "2024 כבר כאן, ואתם עדיין משתמשים בטכניקות של 2010? הגיע הזמן להשתדרג! 🤯"
    - שימוש בשפה מדוברת יומיומית שמתאימה לקהל ישראלי!

  `,

  MYSTERIOUS: `
  - כתוב בטון מסתורי ומסקרן.
  - השתמש ברמזים מרומזים או שאלות שמשאירות מקום לדמיון.
  - שמור על מידע מינימלי כדי ליצור מתח וציפייה.
  - דוגמה: "משהו ענק בדרך... נחשו מה זה הולך להיות? 😉"
    - שימוש בשפה מדוברת יומיומית שמתאימה לקהל ישראלי!

  `,

  FORMAL: `
  - כתוב בסגנון מקצועי ורשמי, שמדגיש אמינות.
  - השתמש במשפטים מסודרים, ללא סלנג או קיצורי דרך.
  - מתאים לפרסומים מוסדיים, דוחות מקצועיים ותוכן פיננסי.
  - דוגמה: "בהתאם למחקרים האחרונים, שוק ה-Ecommerce צפוי לצמוח ב-15% בשנת 2025."
    - שימוש בשפה מדוברת יומיומית שמתאימה לקהל ישראלי!

  `
};
