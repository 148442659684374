import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/Icons/InstagramIcon.svg";
import Card from "../../common/Card";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { AttachmentMedia } from "neword-core";
import { ReactComponent as Edit } from "../../../assets/Icons/EditImage.svg";
import { ReactComponent as Remove } from "../../../assets/Icons/Times.svg";
import MediaEditor from "../MediaEditor";

const Wrapper = styled(Card)`
  position: relative;
  padding: 0px;
  border: none;
  width: 100%;
  max-width: 300px;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", "open sans Hebrew";
  letter-spacing: -0.2px;
`;

const ContentWrapper = styled.div`
  font-size: 12.21px;
  display: flex;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 12px;
  bottom: 19px;
  z-index: 99;
  border-radius: 4px;
  margin-right: auto;
  background: #ffffffac;
  width: 25px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const PostImage = styled.img`
  aspect-ratio: 9/16;
  border-radius: 8px;
  object-fit: cover;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: -7px;
`;

const ToolWrapper = styled.div`
  gap: 10px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: white;
  }
  display: flex;
  height: 40px;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 6px;
  transition: background-color 0.2s;
  z-index: 99999;
  svg {
    fill: white;
    width: 20px;
    height: 20px;
  }

  path {
    fill: white;
  }

  &:hover {
    background-color: #2c2c2c;
  }
`;

const Tools = styled.div`
  gap: 10px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
`;

function IgStoryPreview<FormDataType extends FieldValues>({
  name,
}: {
  name: Path<FormDataType>;
  reel?: boolean;
}) {
  const methods = useFormContext();
  const { websiteId } = useParams();
  const website = useRecoilValue(websiteState(websiteId as string));
  const values = useWatch({ control: methods.control, name: name });
  const [showMediaEditorModal, setMediaEditorModal] = useState(false);

  const item: AttachmentMedia | null = useMemo(
    () => values.imageUrls[0],
    [values.imageUrls]
  );

  return (
    <Wrapper className="col-4">
      {/* <IconWrapper>
        <Logo />
      </IconWrapper> */}
      <ImageWrapper>
        {item && (
          <>
            <Tools>
              <ToolWrapper
                onClick={() =>
                  methods.setValue(
                    name + ".imageUrls",
                    values.imageUrls.filter(
                      (m: AttachmentMedia) => m.url !== item.url
                    )
                  )
                }
              >
                <Remove />
                <span>הסר</span>
              </ToolWrapper>
              <ToolWrapper onClick={() => setMediaEditorModal(true)}>
                <Edit />
                <span>עריכת תמונה/וידאו</span>
              </ToolWrapper>
            </Tools>

            {item && item.metadata.type === "VIDEO" ? (
              <video src={item.url} controls style={{ aspectRatio: "9/16" }} />
            ) : (
              <PostImage src={item.url} />
            )}
            {showMediaEditorModal && (
              <MediaEditor
                url={item?.url}
                onProcess={(attachment: AttachmentMedia) =>
                  methods.setValue(
                    name + ".imageUrls",
                    values.imageUrls.map((m: AttachmentMedia) =>
                      m === item ? attachment : m
                    )
                  )
                }
                imageSettings={{
                  imageCropAspectRatio: 9 / 16, // Maintain TikTok's vertical aspect ratio
                }}
                onClose={() => setMediaEditorModal(false)}
              />
            )}
          </>
        )}
      </ImageWrapper>
      {/* <AcountText className="mt-3 flex row-revers">{website?.name}</AcountText> */}

    </Wrapper>
  );
}

export default IgStoryPreview;
