import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import ModalHeader from "./ModalHeader";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../common/form/Button";
import GenericDescriptionInput from "../document/forms/formComponentes/GenericDescriptionInput";
import generatorService from "../../core/services/generator.service";
import { useMemo, useState } from "react";
import { websiteService } from "../../core/services/website.service";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { attachmentsState } from "../../state/attachmentsState";
import Integrations, {
  platforms,
} from "../website/createWebsite/steps/Integrations";
import { PostEntityType } from "neword-core";
import { appIntegrationState } from "../../state/appIntegrationState";
import useIntegrations from "../../hooks/useIntegrations";
import FacebookPagesModal from "../common/form/FacebookPagesModal";
import { AppIntegrationApplicationType } from "../../core/types/appIntegrationApplicationType";

const ButtonStyled = styled(Button)`
  transition: all 0.3s ease;
`;

// Styling for the container
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

// Styling for individual items
// Styling for individual items
const IntegrationItem = styled.div<{ color: string; connected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: ${({ connected, color }) =>
    connected ? `1px solid ${color}` : "1px dashed #ccc"};
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: ${({ connected, color }) =>
    connected ? "#fff" : "#fff"}; /* Ensure background remains white */

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 6px;
  }

  .icon svg {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    transition: all 0.3s ease;
    fill: ${({ color }) => color} !important;

    path {
      fill: ${({ connected }) => (connected ? "#fff" : "inherit")} !important;
    }
  }

  span {
    font-size: 14px;
    color: ${({ connected }) => (connected ? "#333" : "#333")};
    margin-bottom: 5px;
    transition: all 0.3s ease;
  }

  p {
    font-size: 12px;
    color: ${({ connected }) => (connected ? "#333" : "#666")};
    margin: 0;
    transition: all 0.3s ease;
  }

  ${ButtonStyled} {
    pointer-events: ${({ connected }) => (connected ? "auto" : "auto")};
    color: ${({ connected }) => (connected ? "#333" : "#333")};
    transition: color 0.3s ease;
  }

  &:hover {
    background-color: ${({ connected, color }) => (connected ? "#fff" : color)};
    border-color: ${({ color }) => color};

    .icon path {
      fill: ${({ connected }) => (connected ? "#fff" : "#fff")} !important;
    }

    span,
    p {
      color: ${({ connected }) => (connected ? "#333" : "#fff")};
    }

    ${ButtonStyled} {
      color: ${({ connected }) => (connected ? "red" : "#fff")};
    }
  }
`;

const ProfileWrapper = styled.div`
  position: relative;
`;

const FloatingIcon = styled.div<{ color: string }>`
  position: absolute;
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  top: -7px;
  right: -7px;
  background-color: ${({ color }) => color};
  svg {
    fill: #fff;
    width: 15px;
    height: 15px;
  }
`;
const DialogStyled = styled(Dialog)`
  padding: 0;
  .p-dialog-header {
    padding: 0;
  }

  .p-dialog-content {
    padding: 0;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

const Description = styled.p`
  margin: 0 0 1rem 0;
  color: #666;
  font-size: 0.875rem;
`;

const Wrapper = styled.div`
  padding: 20px;
`;

const Subtitle = styled.h3`
  margin-top: 0.5rem;
  font-size: 1rem; /* 16px */
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02625rem;
  color: #666;
`;

const IntegrationsModal: React.FC<{
  onHide: () => void;
  onSuccess?: () => void;
  wantedType?: PostEntityType;
}> = ({ wantedType, onSuccess, onHide }) => {
  const { websiteId } = useParams();

  const [appIntegrations, setAppIntegrations] = useRecoilState(
    appIntegrationState(websiteId as string)
  );

  const {
    facebookPages,
    showFacebookModal,
    getIntegrationToggle,
    setShowFacebookModal,
  } = useIntegrations(websiteId);

  const platformsWithAppIntegrations = useMemo(() => {
    return platforms.map((platform) => {
      const appIntegration = appIntegrations.find(
        (appIntegration) =>
          appIntegration.appIntegrationApplicationType === platform.type
      );
      return {
        ...platform,
        appIntegration,
      };
    });
  }, [appIntegrations]);

  return (
    <DialogStyled
      visible
      position={"center"}
      style={{
        // height: "50vh",
        margin: "0",
        width: "70vw",
        boxShadow: "none",
        borderLeft: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
        padding: 20,
      }}
      onHide={onHide}
      header={
        <>
          <Title>נראה שחסר לך חיבורים</Title>
          <Subtitle>
            התחבר לעמודים הרלוונטים על מנת <strong>לפרסם את התוכן</strong>{" "}
            ולהנות מעוד המון פיצ׳רים שלנו.
          </Subtitle>
        </>
      }
      modal={true}
      draggable={false}
    >
      <Wrapper>
        <GridContainer>
          {platformsWithAppIntegrations.map((platform) => (
            <IntegrationItem
              key={platform.name}
              color={platform.color}
              connected={!!platform.appIntegration?.isEnabled}
              onClick={() => {
                if (platform.disabled) return; // Prevent interaction if disabled
                const change = getIntegrationToggle[platform.type];
                if (change) {
                  change(!platform.appIntegration?.isEnabled);
                }
              }}
              style={
                platform.disabled ? { opacity: 0.7, cursor: "not-allowed" } : {}
              }
            >
              {platform.appIntegration?.isEnabled ? (
                <ProfileWrapper className="position-relative">
                  <img src={platform.appIntegration.imageUrl} alt="" />
                  <FloatingIcon color={platform.color} className="floatingicon">
                    {platform.icon}
                  </FloatingIcon>
                </ProfileWrapper>
              ) : (
                <div className="icon">{platform.icon}</div>
              )}
              {platform.appIntegration?.isEnabled ? (
                <strong>{platform.appIntegration.name}</strong>
              ) : (
                <span>{platform.name}</span>
              )}
              <ButtonStyled
                bgColor="transparent"
                className="p-0 mt-2"
                arrowPlacement={
                  platform.appIntegration?.isEnabled ? "none" : "right"
                }
                disabled={platform.disabled} // Disable the button
              >
                {platform.disabled
                  ? "בקרוב"
                  : platform.appIntegration?.isEnabled
                    ? "התנתק"
                    : "התחברות"}
              </ButtonStyled>
            </IntegrationItem>
          ))}
        </GridContainer>
        {showFacebookModal.open && (
          <FacebookPagesModal
            websiteIdParam={websiteId}
            isInstagram={
              showFacebookModal.type === AppIntegrationApplicationType.INSTAGRAM
            }
            onClose={() =>
              setShowFacebookModal((p) => ({ open: false, type: p.type }))
            }
            pages={facebookPages}
          />
        )}
      </Wrapper>
    </DialogStyled>
  );
};

export default IntegrationsModal;
