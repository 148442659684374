import Card from "../../components/common/Card";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import Button from "../../components/common/form/Button";

import styled from "styled-components";
import { Divider } from "@mui/material";
import { ReactComponent as PlusIcon } from "../../assets/Icons/Plus.svg";
import { ReactComponent as LogOutIcon } from "../../assets/Icons/LogOut.svg";
import CardTitle from "../../components/common/CardTitle";
import { publishIntegrationState } from "../../state/publishIntegrationState";
import { appIntegrationState } from "../../state/appIntegrationState";
import { AppIntegrationApplicationType } from "../../core/types/appIntegrationApplicationType";
import PageTitle from "../../components/common/PageTitle";
import useNavigator from "../../hooks/useNavigator";
import FacebookPagesModal from "../../components/common/form/FacebookPagesModal";
import { IntegrationCard } from "../../integrations/integrationCard";
import { integrationFactory } from "../../integrations/integrationFactory";
import useIntegrations from "../../hooks/useIntegrations";
import { socialIntegrations } from "../../integrations/social/socialIntegrations";
import { Theme } from "../../core/theme/theme";
import { choseLoginMethod } from "../../components/modals/InstagramIntegrationModal";

const CardStyle = styled(Card)<{ borderYellow?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  cursor: pointer;
  & img {
    width: 75px;
  }

  &:hover {
    border-color: ${({ borderYellow }) =>
      borderYellow ? "var(--pink)" : "var(--title-color)"};
  }

  &.disabled-card {
    pointer-events: none;
    opacity: 0.6;
  }

  &:hover .HoverArrow__linePath {
    opacity: 1;
  }

  &:hover .HoverArrow__tipPath {
    opacity: 1;
    transition: transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateX(3px);
  }
`;
const CardStyleInstagram = styled(Card)<{ borderYellow?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  & img {
    width: 75px;
  }

  /* &:hover {
    border-color: ${({ borderYellow }) =>
    borderYellow ? "var(--pink)" : "var(--title-color)"};
  } */

  &.disabled-card {
    pointer-events: none;
    opacity: 0.6;
  }

  &:hover .HoverArrow__linePath {
    opacity: 1;
  }

  /* &:hover .HoverArrow__tipPath {
    opacity: 1;
    transition: transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateX(3px);
  } */
`;
const ProfileCard = styled.div`
  transition: border 0.3s;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 4px;
  background-color: aliceblue;
  padding: 4px;

  img {
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
`;

const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.029rem;
  margin-top: 30px;
  color: var(--title-color);
`;

const SectionSubTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.029rem;
  margin-bottom: 30px;
  color: var(--text-color);
`;

const TitleWrapper = styled.div`
  .mr-2 div:first-child {
    margin-top: 10px;
  }
`;

const IconWrapper = styled.div`
  padding: 14px;
  border-radius: 8px;
  background-color: var(--light-bg);

  svg {
    margin-bottom: -5px;
  }
`;

const ConfigurationPage: React.FC = () => {
  const { websiteId } = useParams();
  const {
    facebookPages,
    showFacebookModal,
    getIntegrationToggle,
    setShowFacebookModal,
    instagramWithInstagramToggle,
  } = useIntegrations();
  const navigate = useNavigator();
  const [appIntegrations, setAppIntegrations] = useRecoilState(
    appIntegrationState(websiteId as string)
  );
  const publishIntegration = useRecoilValue(
    publishIntegrationState(websiteId as string)
  );

  const isCmsConnected = useMemo(
    () => !!publishIntegration,
    [publishIntegration]
  );

  return (
    <>
      <TitleWrapper className="flex justify-content-between align-items-center">
        <PageTitle
          title="חיבורים לסושיאל"
          subtitle="חברו את עמודי הסושיאל שלכם"
        />
      </TitleWrapper>

      <div className="grid">
        {Object.entries(socialIntegrations).map(([key, { Icon, label }]) => {
          const integration = appIntegrations.find(
            (i) =>
              i.appIntegrationApplicationType ===
              (key as AppIntegrationApplicationType)
          );

          const enabled = integration?.isEnabled;

          return (
            <div key={key} className="col-4">
              <CardStyle
                onClick={async () => {
                  let change =
                    getIntegrationToggle[key as AppIntegrationApplicationType];

                  if (
                    !enabled &&
                    key === AppIntegrationApplicationType.INSTAGRAM
                  ) {
                    const choosenLoginMethod = await choseLoginMethod(
                      {} as any
                    );
                    if (choosenLoginMethod === "instagram") {
                      change = instagramWithInstagramToggle;
                    }
                    if (choosenLoginMethod === "cancel") return;
                  }

                  if (change) {
                    change(!enabled);
                  }
                }}
              >
                <div className="flex align-items-center">
                  <IconWrapper>
                    <Icon />
                  </IconWrapper>
                  <CardTitle title={label} className="mr-3 ml-3 mb-0" />
                </div>

                <div className="flex align-items-center">
                  {integration && integration.isEnabled && (
                    <ProfileCard>
                      <img src={integration?.imageUrl} alt="profilee" />
                      <small>
                        <strong>{integration?.name}</strong>
                      </small>
                    </ProfileCard>
                  )}
                </div>
                {enabled ? (
                  <>
                    <div>
                      <Button
                        icon={<LogOutIcon />}
                        disabled={isCmsConnected}
                        textColor="danger"
                      >
                        התנתק
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    {isCmsConnected}
                    <div
                      className="add-integration-button"
                      data-pr-tooltip="ניתן לחבר רק CMS אחד"
                      data-pr-position="left"
                    >
                      <Button arrowPlacement="right" disabled={isCmsConnected}>
                        התחבר
                      </Button>
                    </div>
                  </>
                )}
              </CardStyle>
            </div>
          );
        })}

        <div className="col-4"></div>
      </div>

      <Divider className="mt-5" />

      <div className="mr-2 flex justify-content-between align-items-center">
        <div>
          <SectionTitle>חיבור לאפלקציות</SectionTitle>
          <SectionSubTitle>
            הגדל את האסטרטגיה השיווקית שלך וחבר את הכלים שבהם אתה משתמש כל יום
          </SectionSubTitle>
        </div>
        <a
          href="https://ghostwrites.ai/contact-us/"
          target="_blank"
          rel="noreferrer"
        >
          <Button icon={<PlusIcon />}>בקש אינטגרציה</Button>
        </a>
      </div>
      <div className="grid">
        {Object.entries(integrationFactory).map(([key, value]) => (
          <IntegrationCard
            Logo={value.icon}
            onToggle={
              getIntegrationToggle[key as AppIntegrationApplicationType]
            }
            type={
              AppIntegrationApplicationType[
                key as AppIntegrationApplicationType
              ]
            }
            label={value.label}
            text={value.text}
          />
        ))}
      </div>
      {showFacebookModal.open && (
        <FacebookPagesModal
          isInstagram={
            showFacebookModal.type === AppIntegrationApplicationType.INSTAGRAM
          }
          onClose={() =>
            setShowFacebookModal((p) => ({ open: false, type: p.type }))
          }
          pages={facebookPages}
        />
      )}
    </>
  );
};

export default ConfigurationPage;
