import React, { useRef, useState, useMemo } from "react";
import styled, { keyframes, css } from "styled-components";
import { websiteService } from "../../core/services/website.service";
import { toast } from "react-toastify";
import { attachmentsState } from "../../state/attachmentsState";
import { useRecoilState } from "recoil";
import { useParams } from "react-router";
import { ReactComponent as AddIcon } from "../../assets/Icons/AddImage.svg";
import { ReactComponent as AiImageIcon } from "../../assets/Icons/AiImage.svg";
import Masonry from "@mui/lab/Masonry";
import { Dropdown } from "primereact/dropdown";
import GenerateMediaModal from "../../components/modals/generateMediaModal";

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
`;

const HeaderBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: 600;
`;

const Controls = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const neonAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const ActionButton = styled.div<{ colorful?: boolean }>`
  border: ${(props) => (props.colorful ? "none" : "1px dashed #ddd")};
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  background-color: #fff;
  position: relative;

  svg {
    width: 25px;
    height: 25px;
  }

  ${(props) =>
    props.colorful &&
    css`
      &::before {
        content: "";
        position: absolute;
        inset: -2px;
        border-radius: 0.5rem;
        padding: 1px;
        background: linear-gradient(
          45deg,
          rgb(255, 0, 106),
          rgb(0, 183, 255),
          rgb(76, 0, 255),
          rgb(255, 0, 106),
          rgb(0, 183, 255),
          rgb(255, 230, 0)
        );
        background-size: 600% 600%;
        animation: ${neonAnimation} 12s ease infinite;
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    `}
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

// Removed the old local DragDropArea in favor of a full-page overlay
// For the manual upload section:
const UploadSection = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    span {
      color: var(--primary-purple);
    }
    ${ActionButton} {
      svg {
        fill: var(--primary-purple);
      }
    }
  }
`;

// Full-screen overlay that appears when dragging files over the page
const FullPageOverlay = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.25rem;
`;

const HiddenInput = styled.input`
  display: none;
`;

const MediaItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
`;

const ImageBox = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  border: 1px solid rgb(230, 230, 230);
  transition: border 0.2s;
  &:hover {
    border: 1px solid var(--primary-color);
  }
`;

const VideoBox = styled.video`
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  border: 1px solid rgb(230, 230, 230);
  transition: border 0.2s;
  &:hover {
    border: 1px solid var(--primary-color);
  }
`;

const Checkbox = styled.input`
  position: absolute;
  top: 8px;
  left: 8px;
  width: 20px;
  height: 20px;
  z-index: 2;
`;

const MenuButton = styled.button<{ variant?: "primary" | "danger" }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  ${(props) =>
    props.variant === "danger"
      ? `
    background-color: #fff;
    border: 1px solid #dc3545;
    color: #dc3545;
    
    &:hover {
      background-color: #dc3545;
      color: white;
    }
  `
      : props.variant === "primary"
        ? `
    background-color: #fff;
    border: 1px solid var(--primary-purple);
    color: var(--primary-purple);
    
    &:hover {
      background-color: var(--primary-purple);
      color: white;
    }
  `
        : `
    background-color: #fff;
    border: 1px solid #ddd;
    color: #666;
    
    &:hover {
      border-color: var(--primary-purple);
      color: var(--primary-purple);
    }
  `}
`;

// Main Component
const EnhancedMediaManager: React.FC = () => {
  const { websiteId } = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [existingAttachments, setAttachments] = useRecoilState(
    attachmentsState(websiteId as string)
  );
  const [loading, setLoading] = useState(false);

  // New: track whether user is dragging files over the entire page
  const [isPageDragging, setIsPageDragging] = useState(false);

  // UI states
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("all");
  const [sortOption, setSortOption] = useState("date");
  const [multiSelectMode, setMultiSelectMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // 1) Add global event listeners on mount
  React.useEffect(() => {
    // We'll keep it simple, but note that dragEnter/dragLeave can be tricky.

    const handleWindowDragOver = (e: DragEvent) => {
      e.preventDefault();
      // Only show overlay if they're actually dragging files
      if (e.dataTransfer?.types?.includes("Files")) {
        setIsPageDragging(true);
      }
    };

    const handleWindowDragLeave = (e: DragEvent) => {
      // If they actually left the window entirely or no files in drag
      if (e.screenX === 0 && e.screenY === 0) {
        setIsPageDragging(false);
      }
    };

    const handleWindowDrop = (e: DragEvent) => {
      e.preventDefault();
      setIsPageDragging(false);

      if (!websiteId) return;

      const files = e.dataTransfer?.files;
      if (!files) return;

      setLoading(true);
      (async () => {
        try {
          for (const file of Array.from(files)) {
            const t = toast.loading(`Uploading ${file.name}... Please wait.`);
            try {
              const attachment = await websiteService.uploadAttachmentInChunks(
                file,
                websiteId
              );
              setAttachments((prev) => [attachment, ...prev]);
              toast.update(t, {
                render: `${file.name} uploaded successfully!`,
                type: "success",
                isLoading: false,
                autoClose: 2000,
              });
            } catch (err) {
              console.error(err);
              toast.update(t, {
                render: `Error uploading ${file.name}`,
                type: "error",
                isLoading: false,
                autoClose: 2000,
              });
            }
          }
        } finally {
          setLoading(false);
        }
      })();
    };

    window.addEventListener("dragover", handleWindowDragOver);
    window.addEventListener("dragleave", handleWindowDragLeave);
    window.addEventListener("drop", handleWindowDrop);

    return () => {
      window.removeEventListener("dragover", handleWindowDragOver);
      window.removeEventListener("dragleave", handleWindowDragLeave);
      window.removeEventListener("drop", handleWindowDrop);
    };
  }, [websiteId, setAttachments]);

  // 2) Manual file input for the Upload button
  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (!files || !websiteId) return;

    setLoading(true);
    try {
      for (const file of Array.from(files)) {
        const t = toast.loading(`Uploading ${file.name}... Please wait.`);
        try {
          const attachment = await websiteService.uploadAttachmentInChunks(
            file,
            websiteId
          );
          setAttachments((prev) => [attachment, ...prev]);
          toast.update(t, {
            render: `${file.name} uploaded successfully!`,
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        } catch (err) {
          console.error(err);
          toast.update(t, {
            render: `Error uploading ${file.name}`,
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const toggleSelect = (url: string) => {
    if (!multiSelectMode) return;
    if (selectedItems.includes(url)) {
      setSelectedItems(selectedItems.filter((item) => item !== url));
    } else {
      setSelectedItems([...selectedItems, url]);
    }
  };

  const openPreview = (url: string) => {
    if (multiSelectMode) {
      toggleSelect(url);
      return;
    }
    // open in new window
    const newWindow = window.open("", "_blank");
    if (newWindow) {
      newWindow.document.write(`<img src="${url}" style="max-width:100%;"/>`);
    }
  };

  // Filter & search attachments
  const filteredAttachments = useMemo(() => {
    let data = [...existingAttachments];
    // Commenting out name-based search since no 'originalName' in metadata
    // if (searchTerm.trim() !== "") {
    //   data = data.filter((item) => {
    //     const name = item.metadata?.originalName || "";
    //     return name.toLowerCase().includes(searchTerm.toLowerCase());
    //   });
    // }

    if (filterType !== "all") {
      if (filterType === "images") {
        data = data.filter(
          (item) => item.metadata.type !== "VIDEO" // or item.metadata.type === 'IMAGE'
        );
      } else if (filterType === "videos") {
        data = data.filter((item) => item.metadata.type === "VIDEO");
      }
    }

    // Commenting out date-based sort since no 'createdAt' in metadata
    // if (sortOption === "date") {
    //   data.sort((a, b) => {
    //     ...
    //   });
    // }

    return data;
  }, [existingAttachments, searchTerm, filterType, sortOption]);

  const handleDeleteSelected = () => {
    // placeholder for delete logic
    console.log("Deleting selected items:", selectedItems);
    setSelectedItems([]);
  };

  const filterOptions = [
    { label: "כל סוגי המדיה", value: "all" },
    { label: "תמונות", value: "images" },
    { label: "סרטונים", value: "videos" },
  ];

  const [showGenerateMediaModal, setShowGenerateMediaModal] = useState(false);

  return (
    <Container>
      {/* Full page overlay for drag & drop */}
      <FullPageOverlay visible={isPageDragging}>
        Drop files to upload
      </FullPageOverlay>

      <HeaderBar>
        <Title>המדיה שלי</Title>
        <Controls>
          <Dropdown
            value={filterType}
            options={filterOptions}
            onChange={(e) => setFilterType(e.value)}
            placeholder="Select Media Type"
            className="w-full md:w-14rem"
          />
          <MenuButton
            variant="primary"
            onClick={() => setMultiSelectMode(!multiSelectMode)}
          >
            {multiSelectMode ? "ביטול בחירה" : "בחירה"}
          </MenuButton>
          {multiSelectMode && selectedItems.length > 0 && (
            <MenuButton variant="danger" onClick={handleDeleteSelected}>
              מחיקה ({selectedItems.length})
            </MenuButton>
          )}
        </Controls>
      </HeaderBar>

      <div className="flex gap-10">
        <UploadSection onClick={handleClick}>
          <ActionButton>
            <AddIcon style={{ marginRight: 4 }} />
          </ActionButton>
          <div>
            <span> העלאת תמונה או וידאו</span>
            <small>ניתן גם לגרור את הקובץ</small>
          </div>
          <HiddenInput
            type="file"
            multiple
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </UploadSection>
        <UploadSection
          onClick={() => setShowGenerateMediaModal(true)}
          className="mr-5"
        >
          <ActionButton colorful>
            <AiImageIcon style={{ marginRight: 4 }} />
          </ActionButton>
          <div>
            <span> יצירת תמונה עם AI</span>
            <small> מחולל תמונות מתקדם</small>
          </div>
        </UploadSection>
      </div>

      <Wrapper>
        <Masonry columns={6} spacing={2} style={{ margin: 0 }}>
          {loading && (
            <MediaItemWrapper>
              <span>המדיה נשמרת</span>
            </MediaItemWrapper>
          )}
          {filteredAttachments.map((media) => {
            const url = media.url;
            const isSelected = selectedItems.includes(url);
            return (
              <MediaItemWrapper key={url} onClick={() => openPreview(url)}>
                {multiSelectMode && (
                  <Checkbox
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => toggleSelect(url)}
                  />
                )}
                {media.metadata.type === "VIDEO" ? (
                  <VideoBox src={media.url} controls />
                ) : (
                  <ImageBox src={media.url} alt="attachment" />
                )}
              </MediaItemWrapper>
            );
          })}
        </Masonry>
      </Wrapper>

      {showGenerateMediaModal && (
        <GenerateMediaModal onHide={() => setShowGenerateMediaModal(false)} />
      )}
    </Container>
  );
};

export default EnhancedMediaManager;
