import { Divider } from "primereact/divider";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import IgPreview from "../postPreviews/IgPreview";
import { useRef, useEffect, useState } from "react";
import CardTitle from "../../common/CardTitle";
import TextEditor from "../../common/publishPostModal/TextEditor";
import MultyAttachmentInput from "../../common/form/MultyAttachmentInput";
import Card from "../../common/Card";
import { Message } from "primereact/message";
import instagramPostMediaValidation from "./mediaValdiation/instagramPostMediaValidation";
import CircleBg from "../../../assets/images/bgcirclepurple.png";

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background-size: cover;
  position: relative;
  background-color: var(--light-bg);
  margin-left: -1px;
  margin-top: -1px;
  &::before {
    content: "";
    position: absolute;
    top: 0x;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url(${CircleBg});
    background-size: cover;
    opacity: 0.11; /* Adjust the opacity as needed */
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
  }
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
`;

function InstagramPostForm<FormDataType extends FieldValues>({
  name,
}: {
  name: Path<FormDataType>;
  index?: number;
}) {
  const { control } = useFormContext();

  const { posts = [] } = useWatch({ control }); // Ensuring that posts has a default value

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrlError, setImageUrlError] = useState("");

  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    autoResizeTextarea(); // Initial adjustment on mount or when post content changes
  }, [posts]);
  console.log(posts);
  const [errorMsg, setErrorMsg] = useState("");

  return (
    <div className="grid m-0 h-full">
      <div className="col-8 pl-4 pr-4 pt-4">
        <CardTitle title="פרסום פוסט באינסטגרם" />
        <Divider />
        <div className="post p-2 mb-2">
          <Card className="pb-0">
            <LabelStyled>תוכן הפוסט</LabelStyled>
            <TextEditor name={name} />
            <Divider />
            <LabelStyled>הוספת מדיה</LabelStyled>
            <MultyAttachmentInput
              name={`${name}.imageUrls`}
              validate={(value) => {
                const errorText = instagramPostMediaValidation(value);
                setImageUrlError(errorText);
                if (errorText === "") {
                  return true;
                }

                return errorText;
              }}
            />
            <Message
              className="mb-4"
              severity="info"
              text="יש לשים לב כי סרטונים יעלו כפוסט ולא Reel"
            />
          </Card>
        </div>
        <div className="media p-2 mt-0 ">
          {errorMsg !== "" && <Message severity="warn" text={errorMsg} />}
          {imageUrlError !== "" && (
            <Message severity="warn" text={imageUrlError} />
          )}

          {/* <span>{imageUrlError}</span> */}
        </div>
      </div>
      {/* <div className="col-3">
        <CommentSection>
          <CommentInputWrapper>
            <div className="flex align-items-center">
              <AvatarStyled label={"S"} shape="circle" className="ml-2" />
              <CommentInputCollapsed
                placeholder="רשום תגובה..."
                onFocus={() => setExpandedCommentIndex(0)}
              />
            </div>
            {expandedCommentIndex === 0 ? (
              <>
                <PostButton bgColor="purple" primary>
                  הוסף תגובה
                </PostButton>
              </>
            ) : (
              <></>
            )}
            <SingleCommentWrapper>
              <div className="flex align-items-center gap-10">
                <AvatarStyled label={"S"} shape="circle" className="" />
                <CommentUser>shimi</CommentUser>
                <CommentDate>24/1/24 15:32</CommentDate>
              </div>
              <Comment>אהבתי מאוד</Comment>
            </SingleCommentWrapper>
          </CommentInputWrapper>
        </CommentSection>
      </div> */}
      <div className="col-4 p-0">
        <ImageWrapper className="p-4">
          <IgPreview name={name} />
        </ImageWrapper>
      </div>
    </div>
  );
}

export default InstagramPostForm;
