import { TourNames } from "../../core/types/tourNames";
import { Tour } from "../../core/types/tourType";

export const WELCOME_TOUR: Tour = {
  name: TourNames.WelcomeTour,
  isRunning: false,
  steps: [
    {
      target: ".welcome-step",
      content: "שלום, ברוכים הבאים ל-neword",
      disableBeacon: true,
    },
    {
      target: ".recomendedContainer-step",
      content: "כאן תוכלו להשתמש בתבניות מומלצות לפוסטים ברשתות",
      disableBeacon: true,
    },
    {
      target: ".createWithAI-step",
      content: "כאן תוכלו ליצור פוסטים  עם קצת עזרה מאיתנו",
      disableBeacon: true,
    },

    {
      target: ".contentManagement-step",
      content: " בארגונית התוכן תוכלו לתכנן קדימה ולתזמן פרסומים",
      disableBeacon: true,
    },
    {
      target: ".changeBisness-step",
      content: " רוצים לעבוד על עסק אחר? כאן תוכלו לעבור בין עסקים",
      disableBeacon: true,
    },
    {
      target: ".generalSetings-step",
      content:
        "רוצים לשנות את שם העסק ליצור קהל יעד או לחבר חשבונות נוספים, כל הכלים נמצאים כאן",
      disableBeacon: true,
    },
  ],
};

export const DOCUMENT_TOUR: Tour = {
  name: TourNames.DocumentTour,
  isRunning: false,
  steps: [
    {
      target: ".test2",
      content: (
        <div>
          <p>אפשר לרנדר פה הכל</p>
          <i className="pi pi-star" style={{ fontSize: "1.5rem" }} />
        </div>
      ),
    },
    {
      target: ".test3",
      content: (
        <div>
          <p>הנה תמונה לדוגמה</p>
          <img
            src="https://fastly.picsum.photos/id/1051/150/150.jpg?hmac=_j4pME5rAp5wxYcxe-c2RKpLiJ8nfNzjaHbXSn9zNpg"
            alt="Example"
            style={{ width: "150px", height: "150px" }}
          />
        </div>
      ),
      disableBeacon: true,
    },
  ],
};
