import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend) // Load translations dynamically
  // .use(LanguageDetector) // TODO: add it back!
  .use(initReactI18next) // Connect with React
  .init({
    fallbackLng: "he", // Default language
    debug: true,
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Path for translation files
    },
    interpolation: {
      escapeValue: false, // React already sanitizes content
    },
  });

export default i18n;
