import { AttachmentMedia } from "neword-core";

export default (value: AttachmentMedia[]) => {
  let errorText = "";

  // Separate videos and images
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  const images = value.filter((item) => item.metadata.type === "IMAGE");

  // Validate Videos
  const videoErrors = videos
    .map((video) => {
      const { aspectRatio, resolution, frameRate, duration, size } =
        video.metadata as {
          type: "VIDEO";
          aspectRatio: string;
          resolution: string;
          frameRate: number;
          duration: number;
          size: number;
        };

      // Check aspect ratio
      const [width, height] = aspectRatio.split(":").map(Number);
      const ratio = width / height;
      if (ratio !== 9 / 16 && ratio !== 1 / 1 && ratio !== 16 / 9) {
        return "הווידאו חייב להיות ביחס רוחב-גובה של 9:16, 1:1, או 16:9";
      }

      // Check resolution
      const [resWidth, resHeight] = resolution.split("x").map(Number);
      if (resWidth < 540 || resHeight < 960) {
        return "הווידאו חייב להיות ברזולוציה מינימלית של 540x960 פיקסלים";
      }

      // Check duration
      if (duration < 3 || duration > 600) {
        return "אורך הווידאו חייב להיות בין 3 ל-600 שניות";
      }

      // Check frame rate
      if (frameRate < 24 || frameRate > 60) {
        return "קצב הפריימים של הווידאו חייב להיות בין 24 ל-60 פריימים לשנייה";
      }

      // Check file size
      const maxSizeMB = 500;
      if (size > maxSizeMB * 1024 * 1024) {
        return `גודל הקובץ של הווידאו חייב להיות עד ${maxSizeMB} מגה-בייט`;
      }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Validate Images
  const imageErrors = images
    .map((image) => {
      const { aspectRatio, resolution, size, format } = image.metadata as {
        type: "IMAGE";
        aspectRatio: string;
        resolution: string;
        size: number;
        format: string;
      };

      // Check aspect ratio
      const [width, height] = aspectRatio.split(":").map(Number);
      const ratio = width / height;
      if (ratio !== 9 / 16 && ratio !== 1 / 1 && ratio !== 16 / 9) {
        return "התמונה חייבת להיות ביחס רוחב-גובה של 9:16, 1:1, או 16:9";
      }

      // Check resolution
      const [resWidth, resHeight] = resolution.split("x").map(Number);
      if (
        resWidth < 600 ||
        resHeight < 600 ||
        resWidth > 6000 ||
        resHeight > 6000
      ) {
        return "התמונה חייבת להיות ברזולוציה בין 600x600 ל-6000x6000 פיקסלים";
      }

      // Check file size
      const maxSizeMB = 5;
      if (size > maxSizeMB * 1024 * 1024) {
        return `גודל הקובץ של התמונה חייב להיות עד ${maxSizeMB} מגה-בייט`;
      }

      // Check file format
      if (format !== "image/png" && format !== "image/jpeg") {
        return "פורמט התמונה חייב להיות PNG או JPG";
      }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Combine all errors
  const allErrors = [...videoErrors, ...imageErrors];
  if (allErrors.length > 0) {
    errorText = allErrors.join(". ");
  }

  return errorText;
};
