import React, { useState } from "react";
import Joyride, { CallBackProps } from "react-joyride";
import { useTour } from "../../hooks/useTour";

export const TourProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { tour, stopTour } = useTour();
  const [currentStep, setCurrentStep] = useState(0);

  const handleJoyrideCallback = (data: CallBackProps) => {
    // Update current step
    if (data.index !== undefined) {
      setCurrentStep(data.index);
    }

    if (["finished", "skipped"].includes(data.status)) {
      stopTour(tour.name);
      setCurrentStep(0); // Reset when ends
    }
  };

  return (
    <>
      <Joyride
        steps={tour.steps}
        run={tour.isRunning}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        locale={{
          back: "חזור",
          close: "סגור",
          last: "סיום",
          nextLabelWithProgress: `הבא (${currentStep + 1} / ${tour.steps.length})`,
          skip: "דלג",
        }}
        styles={{
          tooltipContainer: {
            direction: "rtl",
          },
          options: {
            zIndex: 10000,
          },
          buttonNext: {
            backgroundColor: "#6300c1",
            color: "#fff",
            borderRadius: "4px",
          },
        }}
      />
      {children}
    </>
  );
};
