import styled from "styled-components";
import ClockSVG from "../common/ClockSVG";
import { Calendar } from "primereact/calendar";
import { useController, useFormContext } from "react-hook-form";
import { PostEntity } from "neword-core";
import { Nullable } from "primereact/ts-helpers";
import { useRef } from "react";
import { addLocale } from "primereact/api";

const CalendarWrapperStyled = styled.div`
    background: var(--light-bg);
    border:solid 1px var(--border-color);

  &:hover {
    background: var(--light-bg);
    border-color: var(--title-color);
  }

  border-radius: 8px;
  transition-duration: 0.2s;
`;

const CalendarStyled = styled(Calendar)`
  &:hover {
    background: transparent;
  }
  cursor: pointer;
  /* opacity: 0; */
  /* height: 0; */
  input {
    background: transparent;
    cursor: pointer;
    border: none;

    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }
`;

const PostDateInput: React.FC = () => {
  const calendarRef = useRef<any>(null);

  const { control } = useFormContext<{
    posts: PostEntity[];
    scheduleDate: Date;
  }>();

  const scheduleDate = useController({
    control: control,
    name: `scheduleDate`,
  });

  const formatTime = (date: Nullable<Date>) => {
    if (!date) return { hour: 0, minute: 0 };
    return { hour: date.getHours(), minute: date.getMinutes() };
  };

  const time = formatTime(scheduleDate.field.value); // Get the hour and minute from the selected date

  addLocale("he", {
    firstDayOfWeek: 0, // Sunday is typically considered the first day in Israel

    dayNames: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
    dateFormat: "dd/mm/yy", // Set the date format to 'dd/mm/yy'

    dayNamesShort: ["א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ש'"], // Abbreviations for days
    dayNamesMin: ["א", "ב", "ג", "ד", "ה", "ו", "ש"], // Minimal day names
    monthNames: [
      "ינואר",
      "פברואר",
      "מרץ",
      "אפריל",
      "מאי",
      "יוני",
      "יולי",
      "אוגוסט",
      "ספטמבר",
      "אוקטובר",
      "נובמבר",
      "דצמבר",
    ],
    monthNamesShort: [
      "ינו'",
      "פבר'",
      "מרץ",
      "אפר'",
      "מאי",
      "יוני",
      "יולי",
      "אוג'",
      "ספט'",
      "אוק'",
      "נוב'",
      "דצמ'",
    ],
    today: "היום", // 'Today' in Hebrew
    clear: "נקה", // 'Clear' in Hebrew
  });

  return (
    <CalendarWrapperStyled className="h-full flex align-items-center pr-3">
      <ClockSVG hour={time.hour} minute={time.minute} />
      <CalendarStyled
        locale="he"
        placeholder="בחר זמן ותאריך"
        ref={calendarRef}
        value={scheduleDate.field.value}
        onChange={(e) => {
          const value = e.value as Nullable<Date>;
          scheduleDate.field.onChange(value);
        }}
        stepMinute={10}
        showTime
        hourFormat="24"
      />
    </CalendarWrapperStyled>
  );
};
export default PostDateInput;
