import { atom } from "recoil";
import { Tour } from "../core/types/tourType";
import { TourNames } from "../core/types/tourNames";

export const tourState = atom<Tour>({
  key: "tourState",
  default: {
    name: TourNames.NoActiveTour,
    steps: [],
    isRunning: false,
  },
});

export const toursFinishedInSetionState = atom<string[]>({
  key: "toursFinishedInSetionState",
  default: [],
});
