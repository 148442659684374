import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { createConfirmation, confirmable, ConfirmDialog } from "react-confirm";
import Button from "../common/form/Button";
import { AppIntegrationApplicationType } from "../../core/types/appIntegrationApplicationType";
import { socialIntegrations } from "../../integrations/social/socialIntegrations";
import { ComponentType } from "react";

const Title = styled.h2`
  color: var(--Main-TitleColor, #0a2540);
  font-size: 1.125rem; /* 18px */
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.03rem;
  margin-top: 0.5rem;
`;
const ButtonStyled = styled(Button)`
  transition: all 0.3s ease;
`;
const IntegrationItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc ;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
 
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 6px;
  }

  .icon svg {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    transition: all 0.3s ease;
    fill: ${({ color }) => color} !important;


  }

  span {
    font-size: 14px;
    margin-bottom: 5px;
    transition: all 0.3s ease;
  }

  p {
    font-size: 12px;
    margin: 0;
    transition: all 0.3s ease;
  }

  ${ButtonStyled} {
   
    transition: color 0.3s ease;
  }

  &:hover {
    border-color: ${({ color }) => color};

    
    span,
  
  }
`;

const Subtitle = styled.h3`
  margin-top: 0.5rem;
  font-size: 1rem; /* 16px */
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02625rem;
  color: #666;
`;

const IconWrapper = styled.div`
  padding: 14px;
  border-radius: 8px;
  background-color: var(--light-bg);

  svg {
    margin-bottom: -5px;
  }
`;

const DialogStyled = styled(Dialog)`
  width: 550px;
  .p-dialog-header {
    background-color: #f7f9fc;
  }

  .p-dialog-content {
    padding: 1.5rem 1.5rem 0;
  }

  .p-dialog-footer {
    padding: 1rem 1.5rem;
  }

  @media screen and (max-width: 768px) {
    max-width: 90vw;
  }
`;

interface Props {
  show: boolean;
  proceed: (value: "instagram" | "facebook" | "cancel") => void;
}

const InstagramIntegrationModal: ConfirmDialog<
  Props,
  "instagram" | "facebook" | "cancel"
> = ({ show, proceed }) => {
  const FacebookIcon = socialIntegrations.FACEBOOK?.Icon as ComponentType<any>;
  const InstagramIcon = socialIntegrations.INSTAGRAM
    ?.Icon as ComponentType<any>;

  if (!show) return null;
  return (
    <DialogStyled
      visible={show}
      onHide={() => proceed("cancel")}
      header={
        <>
          <Title>בחר דרך התחברות</Title>
          <Subtitle>התחבר דרך אינסטגרם או דרך פייסבוק</Subtitle>
        </>
      }
      modal
    >
      <div className="flex  justify-content-center">
        {" "}
        <IntegrationItem onClick={() => proceed("facebook")} className="ml-4">
          <div className="icon">{<FacebookIcon />}</div>

          <span>פייסבוק</span>

          <ButtonStyled bgColor="transparent" className="p-0 mt-2 ">
            התחברות דרך פייסבוק
          </ButtonStyled>
        </IntegrationItem>
        <IntegrationItem onClick={() => proceed("instagram")}>
          <div className="icon">{<InstagramIcon />}</div>

          <span>אינסטגרם</span>

          <ButtonStyled bgColor="transparent" className="p-0 mt-2">
            התחברות דרך אינסטגרם
          </ButtonStyled>
        </IntegrationItem>
      </div>
    </DialogStyled>
  );
};

export const choseLoginMethod = createConfirmation(
  confirmable(InstagramIntegrationModal)
);
