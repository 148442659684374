import TopMenu from "./topMenu";
import { useLocation } from "react-router";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "./BreadCrumbs/BreadCrumbs";
import SlideIn from "../common/SlideIn";
import SideBar from "../menu/SideBar";
import UpgradePackage from "../modals/UpgradePackage";
import { useRecoilValue } from "recoil";
import { userState } from "../../state/userState";
import Preloader from "../common/Preloader";
import WebsiteTabsNav from "../website/WebsiteTabsNav";
import Announcement from "../common/Announcement";
import Button from "../common/form/Button";
import SubMenuLayout from "../common/SubMenuLayout";
import Protected from "../../middleware/protected";
import { UserRole } from "../../core/types/userRole";
import Intercom from '@intercom/messenger-js-sdk';


interface Props {
  children: React.ReactElement;
  subMenu?: React.ReactElement;
  hideSideBar?: boolean;
}

const ContentWrapper = styled.div<{
  hideSideBar?: boolean;
  isExpanded?: boolean;
}>`
  transition-duration: 0.1s;
  width: ${({ hideSideBar, isExpanded }) =>
    hideSideBar
      ? "100%"
      : isExpanded
      ? "calc(100% - 12rem)"
      : "calc(100% - 80px)"};
`;

const PageWrapperOverflow = styled.div`
  /* padding-right: 30px;
  padding-left: 30px;
  padding-top: 30px; */
  height: calc(100vh - 55px);
  overflow-y: scroll;
  background: var(--light-bg);
`;

const SidebarWrapper = styled.div<{ isExpanded: boolean }>`
  width: ${(props) => (props.isExpanded ? "12rem" : "80px")};
  height: 100vh;
  transition-duration: 0.1s;
`;

const InsideLayout = styled.div<{ paddingRight?: Boolean }>`
  width: 100%;
  padding: 10px;
`;

const AnnouncementText = styled.div`
  color: white;
`;

const SubMenuWrapper = styled.div`
  width: 10rem;
  height: calc(100vh - 115px);
  padding-left: 20px;
  border-left: solid 1px var(--border-color);
  margin-left: 20px;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
`;

const Content = styled.div`
  width: calc(100% - 12rem);
  height: calc(100vh - 115px);
  overflow: scroll;
`;

const PrivateLayout: React.FC<Props> = ({ children, subMenu, hideSideBar }) => {
  const [showPackagesModal, setShowPackagesModal] = useState(false);
  const user = useRecoilValue(userState);

  Intercom({
    app_id: 'c0efdcqp',
    user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: "shay", // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: "email", // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
  });

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  useEffect(() => {
    if (
      user?.company &&
      (!user.company.Subscription || !user.company.Subscription.isActive)
    ) {
      setShowPackagesModal(true);
    }
  }, [user?.company, user?.company?.Subscription?.isActive]);

  return (
    <React.Suspense fallback={<Preloader />}>
      <div className="flex">
        <Protected neededRole={UserRole.EDITOR}>
          {!hideSideBar && (
            <SidebarWrapper isExpanded={isSidebarExpanded}>
              <SideBar toggleSidebar={toggleSidebar} />
            </SidebarWrapper>
          )}
        </Protected>
        <ContentWrapper
          isExpanded={isSidebarExpanded}
          hideSideBar={hideSideBar || user.role === UserRole.CLIENT}
        >
          <TopMenu />

          <PageWrapperOverflow>
            <InsideLayout>
              {subMenu ? (
                <Wrapper>
                  <SubMenuWrapper>{subMenu}</SubMenuWrapper>
                  <Content>{children}</Content>
                </Wrapper>
              ) : (
                children
              )}
            </InsideLayout>
          </PageWrapperOverflow>
          
        </ContentWrapper>
      </div>
    </React.Suspense>
  );
};

export default PrivateLayout;
