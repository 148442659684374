import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import styled from "styled-components";
import { FacebookPage } from "../../../core/entities/facebookPage";
import appIntegrationService from "../../../core/services/appIntegration.service";
import { useParams } from "react-router-dom";
import { AppIntegrationApplicationType } from "../../../core/types/appIntegrationApplicationType";
import { AppIntegrationType } from "../../../core/types/appIntegrationType";
import { useRecoilState } from "recoil";
import { appIntegrationState } from "../../../state/appIntegrationState";
import { LoginWith } from "../../../core/types/loginWith";

interface ModalProps {
  onClose: () => void;
  pages: FacebookPage[];
  isInstagram: boolean;
  websiteIdParam?: string;
}

const Grid = styled.div`
  display: flex; /* Change to flex */
  flex-wrap: wrap; /* Allow wrapping of items */
  gap: 10px; /* Add gap between items */
  justify-content: center;
  flex-direction: column;
`;

const ProfileCard = styled.div`
  border: 1px solid var(--border-color);
  transition: border 0.3s;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;

  &:hover {
    border: 1px solid var(--title-color);
  }

  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;

const Title = styled.h1`
  color: #0a2540;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 2.5rem */
  letter-spacing: -0.075rem;
  width: auto;
  text-align: center;
`;

const SubTitle = styled.h2`
  color: #425466;
  text-align: center;
  font-weight: 300;
`;

const WrapperX = styled.div`
  margin-top: 40px;
`;

const FacebookPagesModal: React.FC<ModalProps> = ({
  onClose,
  pages,
  isInstagram,
  websiteIdParam,
}) => {
  const { websiteId } = useParams();
  const [appIntegrations, setAppIntegrations] = useRecoilState(
    appIntegrationState(websiteIdParam ?? (websiteId as string))
  );

  const onClick = async (page: FacebookPage) => {
    const appIn = await appIntegrationService.createAppIntegrationByWebsiteId({
      websiteId: websiteIdParam ?? (websiteId as string),
      appIntegrationApplicationType: isInstagram
        ? AppIntegrationApplicationType.INSTAGRAM
        : AppIntegrationApplicationType.FACEBOOK,
      appIntegrationType: AppIntegrationType.POST,
      accessToken: page.access_token,
      isEnabled: true,
      externalId: isInstagram ? page.instagramAccount || undefined : page.id,
      imageUrl: page.profileImage,
      name: page.name,
      loginWith: LoginWith.FACEBOOK,
    });

    setAppIntegrations([
      ...appIntegrations.filter((a) => a.id !== appIn.id),
      appIn,
    ]);

    onClose();
  };

  const platformName = isInstagram ? "אינסטגרם" : "פייסבוק";

  return (
    <Dialog
      header={
        <WrapperX>
          <Title>עמודי {platformName}</Title>
          <SubTitle>בחר עמוד {platformName} (יחיד) לניהול</SubTitle>
        </WrapperX>
      }
      visible
      onHide={onClose}
      modal
    >
      <br />
      {
        <Grid>
          {pages
            .filter((page) => (isInstagram ? page.instagramAccount : true))
            .map((page) => (
              <ProfileCard onClick={() => onClick(page)} key={page.id}>
                {page.profileImage && (
                  <img src={page.profileImage} alt="profilee" />
                )}
                <div>{page.instagramUser || page.name}</div>
              </ProfileCard>
            ))}
        </Grid>
      }
    </Dialog>
  );
};

export default FacebookPagesModal;
